.SecOneApplicationMain {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120vh;
    justify-content: space-between;

    .SecOneApplicationMainWrapper {
        margin-top: 150px;
        display: flex;
        // align-items: center;
        justify-content: center;
        width: 95%;
        // background: rgb(224, 182, 182);
    }

    .leftApplication {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        // background: rgb(210, 243, 168);
        span {
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            /* or 19px */


            /* Text color */

            color: #2F3130;

            opacity: 0.5;

            /* Inside auto layout */

            flex: none;
            order: 2;
            flex-grow: 0;

            opacity: 0.5;
        }

        h2 {
            margin-top: 30px;
            font-family: 'DM Serif Display';
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 130%;
            /* or 62px */

            text-transform: capitalize;

            color: #2F3130;
        }
    }

    .rightApplication {
        margin-top: 40px;
        flex: 2;
        // background: rgb(168, 194, 243);

        form {
            display: grid;
            grid-template-columns: auto auto;

            justify-content: space-between;

            button {
                width: 300px;
                height: 50px;
                outline: none;
                border: none;
                background: #7C5AC7;
                border-radius: 10px;
                font-family: Lexend;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #FFFFFF;
                transition: all 0.3s ease;

                &:hover {
                    background: #6040a3;
                    cursor: pointer;

                }
            }

            .file {
                border: 1px dashed #219EBC;

                display: none;
                align-items: center;
                justify-content: center;
            }

            .fileT {
                height: 50px;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                font-family: 'Lexend';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                /* or 18px */

                letter-spacing: 0.004em;

                color: #2F3130;

                background: rgba(124, 90, 199, 0.1);
                border: 1px dashed #7C5AC7;
                box-sizing: border-box;
                border-radius: 10px;

                p {
                    margin: 0 5px;
                    color: #7C5AC7;
                    text-decoration: underline;
                }
            }

            .filecon {}

            .messageCon {
                grid-column: 1/3;
                grid-row: 4/4;
                width: 100% !important;
                height: 150px !important;


                textarea {
                    width: 100%;
                    height: 100% !important;

                    border: 1px solid #fff;
                    outline: none;
                    background: #ffffff;
                    border: 1px solid rgba(47, 49, 48, 0.2);
                    border-radius: 12px;
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgb(0, 0, 0);
                    resize: none;
                    padding: 10px;
                }
            }

            .expCon {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .formItemCon {
                    width: 190px;
                }
            }

            .formItemCon {
                margin: 1vh 0;
                display: flex;
                flex-direction: column;
                width: 410px;

                span {
                    font-family: 'Lexend';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 130%;
                    /* or 18px */
                    
                    letter-spacing: 0.004em;
                    
                    color: #2F3130;
                    
                    opacity: 0.5;
                    
                    /* Inside auto layout */
                    
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: 5px;
                }

                input {
                    height: 50px;
                    border: none;
                    outline: none;

                    border-radius: 10px;
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgb(0, 0, 0);
padding-left: 10px;
                    background: #ffffff;
                    border: 1px solid rgba(47, 49, 48, 0.2);
                    border-radius: 12px;
                }



                .dropdown {

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 190px;
                    position: relative;
                    left: -10px;

                    &:hover {
                        cursor: pointer;
                    }

                    &.active {

                        .dropdown-select {
                            border-end-start-radius: 0px;
                            border-end-end-radius: 0px;
                        }

                        .dropdown-list {
                            display: flex;
                        }
                    }

                    .dropdown-select {
                        padding: 0 5%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 90%;
                        height: 50px;
                        background-color: white;
                        border: 1px solid rgba(47, 49, 48, 0.2);
                        border-radius: 12px;


                    }

                    .dropdown-list {

                        // padding-top: 2%;
                        // background-color: #F2F3F3;
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        width: 190px;
                        transition: all 0.5 ease;
                        position: absolute;
                        top: 50px;
                        z-index: 10;


                    }

                    .dropdown-list-item {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 1% 0;
                        width: 100%;
                        background-color: #ffffff;
                        border: none;
                        outline: none;


                        /* identical to box height */
                         
                        border-right: 1px solid rgba(47, 49, 48, 0.2);
                        border-left: 1px solid rgba(47, 49, 48, 0.2);
                        color: rgb(0, 0, 0);



                        &:last-of-type {

                            border-end-start-radius: 10px;
                            border-end-end-radius: 10px;
                            border-bottom: 1px solid #fff;
                        }

                        &:hover {
                            // background-color: #0a6da3;
                            background-color: #219EBC;
                            // #219EBC
                            cursor: pointer;
                        }

                    }
                }


            }
        }
    }
}


@media only screen and (max-width: 1325px) {

    .SecOneApplicationMain .rightApplication form .formItemCon {
        width: 350px
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        width: 160px
    }

}

@media only screen and (max-width: 770px) {
    .SecOneApplicationMain .SecOneApplicationMainWrapper {
        flex-direction: column;
    }

    .SecOneApplicationMain .leftApplication {
        flex: none;
        align-items: flex-start;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        width: 20vw;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown .dropdown-select {
        width: 90%;

    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        align-items: flex-start;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown .dropdown-list {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .SecOneApplicationMain .SecOneApplicationMainWrapper {
        width: 90%;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon {
        width: 100%;
        padding: 0.3vh 0;
    }

    .SecOneApplicationMain .rightApplication form {
        display: flex;
        flex-direction: column;
        padding-bottom: 5vh;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        width: 95%;
    }
}