.waiting-list {
    background-image: url('https://lilypads.s3.us-east-2.amazonaws.com/images/cover/waiting-list-bg.jpg');
    background-size: cover;
    background-position: centers;
    position: relative;
    overflow: hidden;
}
.waiting-list-img {
    position: absolute;
    top: 12%;
    left: 55%;
}