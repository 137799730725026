.join-waiting-list-bg {
  background: #fff url('https://lilypads.s3.us-east-2.amazonaws.com/images/join-waiting-list/Join-the-waiting-list.jpg') no-repeat top center;
}
.join-waiting-list-hero-img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
}
.home-tabs .nav-link + .list {
  display: none;
}
.home-tabs .nav-link.active + .list {
  display: block;
}
.join-waiting-list {
  overflow: hidden;
}
.join-waiting-list-tab-content {
  position: absolute;
  top: 0;
  width: auto;
  left: 50%;
}
.join-waiting-list-now {
  background: transparent url("https://lilypads.s3.us-east-2.amazonaws.com/images/join-waiting-list/bg.jpg")
    no-repeat center center;
  background-size: cover;
  overflow: hidden;
}
.join-waiting-list {
  background: #fff url('https://lilypads.s3.us-east-2.amazonaws.com/images/join-waiting-list/join-bg.jpg') no-repeat center center;
  background-size: cover;
}
/* RESPONSIVE: RAHUL */
@media screen and (max-width: 1559px) {
  .join-waiting-list-hero-img {
    max-width: 50%;
    object-fit: contain;
    height: auto;
    bottom: 0;
    top: unset;
  }
}

@media screen and (max-width: 991px) {
  .join-waitlist-tabs .nav{
    overflow: auto;
  }
  .join-waiting-list-hero-img {
    position: static;
    margin: 0 auto;
    display: block;
  }

  .join-waiting-list-tab-content {
    position: static;
    padding: 0 50px;
  }

  .join-waiting-list .home-tabs .nav-link.active + .list {
    display: none;
  }

  .join-waiting-list .home-tabs .nav-link h5 {
    white-space: nowrap;
  }
}

@media screen and (max-width: 575px) {
  .join-waiting-list-tab-content {
    padding: 0 20px;
  }
}
/* RESPONSIVE: RAHUL END */

.cricle-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8rem;
  right: 4%;
}
.cricle-wrapper .cricle {
  width: 150px;
  height: 150px;
  position: relative;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
}
.cricle-wrapper span {
  position: absolute;
  font-size: 12px;
}
.cricle-wrapper .cricle h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1499px) {
  .cricle-wrapper{
    right: 15px;
  }
}