.KnwoledgeCenterMain {
  .mobTopName {
    display: flex;
    align-items: center;
    margin: 0 5%;
    margin-top: 100px;
    justify-content: space-between;
    p {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      /* identical to box height, or 20px */

      color: #9e2349 !important;
    }
    svg {
      position: relative;
      top: -3.5px;
      margin-right: 10px;
    }
    .mobTopName-i-l {
      align-items: center;
      display: flex;
    }

    // justify-content: center;
  }
  .knwoledgecenter-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5%;
    margin-top: 101px;
    border-bottom: 1.2px solid #2f313049;
    padding-bottom: 13px;
    margin-bottom: 30px;
  }
  .h-m {
    min-width: 30%;
    position: relative;
    left: 5%;
  }
  .h-r {
    display: flex;
    .btncon {
      margin-left: 20px;
      display: flex;
      align-items: center;
      color: #2f3130;
      svg {
        margin-right: 10px;
      }
    }
  }

  .KC-content {
    display: flex;
    // background-color: red;
    padding: 0 5%;
    align-items: flex-start;
    justify-content: space-betweens;

    &.mobN {
      background: #ffffff;
      border: 1px solid rgba(44, 42, 49, 0.2);
      border-radius: 10px;
      margin: 0 5%;
      padding: 0 2%;
      margin-top: 20px;
      .itopMargin {
        width: 100%;
        opacity: 0.2;
        height: 1.2px;
        background-color: #2f3130;
        margin-top: 20px;
      }
      .kc-m-con-title {
        margin-top: 20px;
      }
      .accordion-body {
        p,
        span {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          /* or 25px */

          letter-spacing: 0.004em;

          color: #979898 !important;
        }
      }
      .accordion-header {
        button {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 700;
          font-size: 16px !important;
          line-height: 144%;
          /* or 23px */

          letter-spacing: 0.004em;

          color: #2f3130;
        }
      }
    }

    .KC-content-L {
      width: 300px;
      height: 841px;
      background: #f5f5f5;
      border-radius: 30px;
      padding: 20px;
      overflow: hidden;
      word-break: keep-all;
      //text break
      .kc-l-con {
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
      .kc-l-con-t {
        display: flex;
        margin-bottom: 4px;
        img {
          width: 20px;
          margin-right: 8px;
        }
        font-family: "Lexend";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
        text-transform: uppercase;

        /* Primary 2 */

        color: #9e2349;
      }
      .kc-l-con-b {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 20px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        /* Text color */

        .menuHolder {
          cursor: pointer;
          ul {
            margin-bottom: 5px;
            display: none;
            // &.active {
            //   display: flex;
            //   flex-direction: column;
            //   li {
            //     list-style: none;
            //     margin-left: 3px;
            //     margin-bottom: 3px;
            //   }
            // }
          }
        }

        color: #2f3130;
        p {
          padding: 6px 14px;
          transition: all 0.5s ease;
          border: 1px solid #7c5ac700;
          overflow: hidden;
          &.active {
            padding: 14px 14px;
            background: #ffffff;
            border: 1px solid #7c5ac7;
            border-radius: 12px;
            color: #7c5ac7;
          }
        }
      }
    }
    .KC-content-M {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      width: 80%;
      .kc-m-con-title {
        display: flex;
        align-items: center;

        font-family: "DM Serif Display";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 130%;
        /* identical to box height, or 42px */

        text-transform: capitalize;

        color: #2f3130;
        svg {
          margin-right: 10px;
        }
      }
    }
    .KC-content-R {
      width: 280px;
      .kc-r-con-t-p {
        p {
          cursor: pointer;
        }
      }
      .kc-r-con-t {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */
        color: #2f3130;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1.2px solid #2f313046;
      }
      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      color: #2f3130;
    }
  }
}

.accordionMain {
  .accordion-button:not(.collapsed)::after,
  .accordion-button::after {
    width: 20px !important;
    height: 20px;
    transform: scale(2);
    background-image: url("close.png");
    // background-size: 20px;
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) scale(2) !important;
    background-image: url("open.png");
  }

  .accordion-button {
    font-family: "Lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    letter-spacing: 0.004em;

    color: #2f3130;
  }
  .accordion-collapse {
    li {
      color: black;
    }
  }
}

.kc-r-con-t-p {
  p {
    width: fit-content;
    padding-bottom: 3px;
    &.active {
      color: #7c5ac7;
      border-bottom: 1.2px solid #7c5ac7;
    }
  }
}

@media only screen and (max-width: 768px) {
  .KC-content {
    // flex-direction: column;
    align-items: center !important;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px !important;
    column-gap: 30px !important;

    .menuHolder {
      ul {
        margin-bottom: 5px;
        display: none;
        &.active {
          display: flex !important;
          flex-direction: column;
          li {
            list-style: none;
            margin-left: 3px;
            margin-bottom: 3px;
          }
        }
      }
    }
    .KC-content-L {
      height: fit-content !important;
    }

    .KC-content-R {
      display: none;
      margin-top: 40px !important;
    }
  }
  .KnwoledgeCenterMain .knwoledgecenter-Header {
    margin-top: 50px;
    justify-content: center;
    left: -20px;
    // top: -10px;
    .h-m {
      min-width: 100%;
      margin-left: 0;
      margin-top: 0;
      left: 0;
      p {
        margin-left: 3%;
        margin-top: 30px;
      }
    }
  }
  .KC-content {
    .KC-content-M {
      width: 100% !important;
      margin: 0 !important;
      .accordion-button {
        &::after {
          position: relative;
          left: 10px;
        }
      }
    }
  }
}
