.perpectivecard{
    display:flex;
    justify-content:center;
    margin: auto;
    
}
.perpectivecardindividual{
  margin-top: -20px;
}
.CardHeading{
    font-family: 'Lexend';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
margin-bottom: 10px;
}
.CardParagraph{
    font-family: 'Lexend';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
}
.inteligencemind{
    margin-left: -280px;

}
.color-font{
    color: black;
}
.inteligencemindalingnment{
    display: flex;
    justify-content: center;
   margin-top: 100px;
   margin-bottom: 160px;
}
.btn-border{
    background-color: var(--white);
    color: #7C5AC7 ;
    border: 1.5px solid #7C5AC7 !important;
}
.LPDiscoverSection3{
    background-color:#A6244B10; 
    overflow: hidden;  
    z-index: 0;
}
.LPDiscoverSection3background{
    background-image: url('../../assets/images/Manage/Mask\ Group\ \(20\).png');
    background-repeat: no-repeat;
    z-index: 1;
    background-position-x: right;
    background-position-y: top;
  
}
.discoverfeatureresponsiveness{
    display:flex;
    justify-content:center;
}
.Section3space{
    padding-top: 72px;
    /* padding-bottom: 72px; */

}
.featuresicons{
    margin-top: 35px;
    margin-bottom: 25px;
}
.WhatWeEmphasize{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
.WhatWeEmphasizecard{
    width: 410px;

background: #FFFFFF;
box-shadow: -40px 40px 70px rgba(166, 36, 75, 0.1);
border-radius: 40px;
padding: 30px;
margin-left: 15px;
margin-right: 15px;

margin-bottom: 80px;
}
.WhatWeEmphasizecardNumber{
    margin-right: auto;
    margin-left: 220px;
    margin-top: -60px;
    width: 138px;
}
.WhatWeEmphasizecardicon{
    width:54.55px;
    margin-bottom:15px;
}
.LaunchaProperty
{
 width: 83%;
 margin: auto;
 display: flex;
  justify-content: center;
  align-items: center; 
}
.LaunchaPropertyBlock{
    width: 50%;
    margin: auto;
    display: flex;
     justify-content: center;
     align-items: center; 
}
.propertysyndicatecontent{
    width: 50%;
}
.propertysyndicate{
    width:83%;
    margin: auto;
    display: flex;
    justify-content: center;
}
.propertysyndicateimage{
    width:30%;
}
.btn-space-dis{
    margin-top: 35px !important;
}

.DiscoverAccordion{
    background: transparent;
    width: 71%;
    margin: auto;
    padding-bottom: 90px;
}
.DiscoverAccordionitems{
    border: none;
    background: #FFFFFF;
box-shadow: 10px 10px 20px rgba(166, 36, 75, 0.1);
border-radius: 20px;
margin-bottom: 10px;
}
.DiscoverAccordionheading .accordion-button{
    font-family: 'Lexend';
font-style: normal;
font-weight: 600;
font-size: 16px !important;
line-height: 24px;
border-radius: 15px;
}
.DiscoverAccordionheading .accordion-button:not(.collapsed){
    border: none;
   color: black;
   background-color: transparent;

}
.DiscoverAccordionheading .accordion-button:not(.collapsed)::after,
.DiscoverAccordionheading .accordion-button::after{
   
    background-image: url("../../assets/images/Discover/Vector\ \(8\).png");
   
}
 .discoverfeatureresponsivenessrow1{
    display:flex;
    /* margin-bottom:40px; */
}
@media screen and (max-width:768px) {
    .Discoverresponsiveness .perpectivecard{
        display: unset;
    }
    .LPDiscoverSection3
    {
        display: unset;
       
    }
    .inteligencemind{
        margin-left: 0px;
    }
    .inteligencemind .inteligencemindimg{
        margin-left: -10px;
        width: 321px;
    }
    .LaunchaProperty{
        display: unset;
    }
    .LaunchaPropertyBlock{
        width: auto;
        margin-bottom: 20px;
    }
    .propertysyndicatecontent{
        width: auto;
        margin: 10px!important;
    }
    .Discoverresponsiveness{
        margin-left: 20px;
    }
    .WhatWeEmphasize{
        display: unset;
        
    }
}
@media screen and (max-width:1200px) {
    .inteligencemindalingnment{
        display: grid;
        width: 83%;
    margin: auto;
    }
    .discoverfeatureresponsiveness,
    .discoverfeatureresponsivenessrow1{
        display: unset;
        margin: auto !important;
    }
    .featureimg{
        width: 315px;
    }
    
    
    .WhatWeEmphasizecardNumber{
        display: none;
    }
    .DiscoverAccordionheading .accordion-button{
        font-size: 12px !important; 
        line-height: 16px;
        padding: 30px
    }
}
@media screen and (max-width:615px) {
    .propertysyndicate{
        display: unset;
    }
}
@media screen and (max-width:1315px)  {
    .propertysyndicateimageresponsive{
       width:100%;
      

    }
    .WhatWeEmphasizecard{
        width: 100%;
    }
}

@media screen and (min-width:2000px) {
    .inteligencemind{
        margin-left: 0px;
        display: flex;
    justify-content: end;
    }
}