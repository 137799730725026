@font-face {
  font-family: "DM Serif Display";
  src: url("./assets/fonts/DMSerifDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/DMSerifDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Medium.woff2") format("woff2"),
    url("./assets/fonts/Lexend-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Regular.woff2") format("woff2"),
    url("./assets/fonts/Lexend-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Lexend-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Bold.woff2") format("woff2"),
    url("./assets/fonts/Lexend-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Lexend-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("./assets/fonts/Lexend-Black.woff2") format("woff2"),
    url("./assets/fonts/Lexend-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Taviraj";
  src: url("./assets/fonts/Taviraj-Bold.woff2") format("woff2"),
    url("./assets/fonts/Taviraj-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Urbanist-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-Black.woff2") format("woff2"),
    url("./assets/fonts/Urbanist-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Urbanist-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-Medium.woff2") format("woff2"),
    url("./assets/fonts/Urbanist-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-Regular.woff2") format("woff2"),
    url("./assets/fonts/Urbanist-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-Bold.woff2") format("woff2"),
    url("./assets/fonts/Urbanist-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DM Serif Display";
  src: url("./assets/fonts/DMSerifDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/DMSerifDisplay-Regular.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Reey";
  src: url("./assets/fonts/Reey-Regular.woff2") format("woff2"),
    url("./assets/fonts/Reey-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --purple: #7d59c3;
  --dark: #2f3130;
  --maroon: #9e2349;
  --white: #fff;
  --bgDark: #202221;
  --lightWhite: rgba(255, 255, 255, 0.5);
  --fontDMSerif: "DM Serif Display", serif;
  --fontTaviraj: "Taviraj", serif;
  --fontUrbanist: "Urbanist", sans-serif;
  --fontLexend: "Lexend", sans-serif;
  --fontReey: "Reey", sans-serif;
  --bgDarkGray: #2a2d2b;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background: var(--white);
  font-family: var(--fontUrbanist);
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
}
body.header-secondary:before {
  content: "";
  backdrop-filter: blur(20px);
  top: 0;
  width: 100%;
  left: 0;
  height: 71px;
  position: absolute;
}
a {
  text-decoration: none;
}
.font-dm-serif {
  font-family: var(--fontDMSerif) !important;
}

.font-taviraj {
  font-family: var(--fontTaviraj) !important;
}

.font-urbanist {
  font-family: var(--fontUrbanist) !important;
}

.font-lexend {
  font-family: var(--fontLexend) !important;
}

.font-reey {
  font-family: var(--fontReey) !important;
}

.color-maroon {
  color: var(--maroon);
}
.color-purple {
  color: var(--purple);
}
a.color-purple:hover {
  color: #884bae;
}
.color-white {
  color: var(--white);
}
.color-dark {
  color: var(--dark);
}
.color-light-dark {
  color: var(--lightWhite);
}
.bg-light {
  background: #f8f6fc !important;
}
.color-gray {
  color: rgba(47, 49, 48, 0.5);
}
.bg-dark {
  background: var(--bgDark) !important;
}
.bg-dark-gray {
  background: #2a2d2b;
  box-shadow: 10px 10px 30px 0px #00000026;
}

.fw-800,
.fw-extrabold {
  font-weight: 800 !important;
}

.fw-700,
.fw-bold {
  font-weight: 700 !important;
}

.fw-600,
.fw-semibold {
  font-weight: 600 !important;
}

.fw-500,
.fw-medium {
  font-weight: 500 !important;
}

.fw-400,
.fw-normal {
  font-weight: 400 !important;
}

.h1,
h1 {
  font-size: 7.1rem;
  font-family: var(--fontDMSerif);
  line-height: 1.3;
}

.h1.small,
h1.small {
  font-size: 4.8rem;
}

.h2.big,
h2.big {
  font-size: 4rem;
}

.h2,
h2 {
  font-size: 3.6rem;
  font-family: var(--fontDMSerif);
  line-height: 1.5;
}

.h3,
h3 {
  font-size: 3.2rem;
  font-family: var(--fontDMSerif);
}

.h3.big,
h3.big {
  font-size: 3.5rem;
}

.h4,
h4 {
  font-size: 2.8rem;
}

.h5,
h5 {
  font-size: 25px;
}

.h5.small,
h5.small {
  font-size: 22px;
}

.h6,
h6 {
  font-size: 20px;
}

p.big {
  font-size: 18px;
}

p {
  font-size: 16px;
  color: var(--dark);
  letter-spacing: 0.4px;
}

p.small {
  font-size: 14px;
}

p.x-small {
  font-size: 12px;
}

.container {
  padding: 0 15px;
  /* max-width: 1320px; */
  position: relative;
  z-index: 1;
}

.space-top {
  padding-top: 8rem;
}
.space-bottom {
  padding-bottom: 8rem;
}
.space-small-top {
  padding-top: 5rem;
}
.space-small-bottom {
  padding-bottom: 5rem;
}
.subtitle {
  color: var(--maroon);
  font-weight: 600;
  font-family: var(--fontUrbanist);
  position: relative;
  padding-left: 3rem;
}
.subtitle.no-line {
  padding-left: 0;
}
.subtitle:not(.no-line)::before {
  content: "";
  width: 2.4rem;
  height: 4px;
  background-color: var(--maroon);
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.img-cover {
  object-fit: cover;
}
/*buttons start */
.btn {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 3.7rem;
  border-radius: 2rem;
  border: 2px solid;
  height: 60px;
  min-width: 15rem;
  text-align: center;
  font-family: var(--fontLexend);
  transition: 0.3s all;
}
.btn.bold {
  font-size: 16px;
  font-weight: 800;
}
.btn.btn-white {
  border-color: var(--white);
  background-color: var(--white);
  color: var(--purple);
}
.btn.btn-white:hover {
  border-color: #d5cee1;
  background-color: #d5cee1;
}
.btn.btn-bordered {
  border-color: var(--white);
  color: var(--white);
  background-color: transparent;
}
.btn.btn-bordered:hover {
  background-color: rgba(255, 255, 255, 0.144);
}
.btn-bordered-purple {
  border-color: var(--purple);
  color: var(--purple);
  background-color: transparent;
}
.btn-bordered-purple:hover {
  background-color: var(--purple);
  color: var(--white);
}
.btn:focus {
  box-shadow: none;
}
.btn.btn-purple {
  background-color: var(--purple);
  color: var(--white);
  border-color: var(--purple);
}
.btn.btn-purple:hover {
  background-color: transparent;
  border-color: #884bae;
  color: var(--purple);
}
.btn.btn-dark {
  background: #2e302f;
  border-color: #2e302f;
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.15);
}
.btn-shadow {
  box-shadow: 10px 10px 30px 0px #2f313026;
}
.anim-shrink:hover {
  transform: scale(0.9);
}
/*buttons end*/
.opacity-50 {
  opacity: 0.5;
}
.app-main {
  margin-top: -101px;
  overflow: hidden;
}
.inner-hero {
  padding-top: 16rem;
}
.gredient-bg {
  background: transparent
    url("https://lilypads.s3.us-east-2.amazonaws.com/images/cover/gredient-bg.png")
    center center no-repeat;
  background-size: cover;
}
.drop-shadow-light {
  filter: drop-shadow(0px 15px 50px rgba(47, 49, 48, 0.1));
}
.drop-shadow-purple {
  filter: drop-shadow(10px 10px 30px #7c5ac726);
}
.min-h-auto {
  min-height: auto !important;
}
.circle-icon {
  min-width: 9rem;
  width: 9rem;
  height: 9rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--purple);
}
.circle-icon img,
.circle-icon svg {
  max-width: 5rem;
  max-height: 5rem;
}
.bg-light-maroon {
  background-color: rgba(158, 35, 74, 0.1);
}
.circle-icon.light-maroon {
  background-color: rgba(158, 35, 74, 0.1) !important;
  color: var(--maroon) !important;
}
.circle-icon.w-80p {
  min-width: 8rem;
  width: 8rem;
  height: 8rem;
}
.circle-icon.w-70p {
  min-width: 7rem;
  width: 7rem;
  height: 7rem;
}
.circle-icon.w-60p {
  min-width: 60px;
  width: 60px;
  height: 60px;
}
.custom-v-tabs {
  position: relative;
}
.custom-v-tabs .custom-tab-link:before,
.custom-v-tabs:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #dedede;
  display: block;
  border-radius: 3px;
  left: 0;
  top: 0;
}
.custom-v-tabs .custom-tab-link {
  position: relative;
  cursor: pointer;
}
.custom-v-tabs .custom-tab-link:hover,
.custom-v-tabs .custom-tab-link {
  color: var(--dark);
  opacity: 0.5;
  transition: 0.3s all;
  padding: 15px 15px 15px 25px;
}
.custom-v-tabs .custom-tab-link.active {
  opacity: 1;
}
.custom-v-tabs .custom-tab-link.active:before {
  background-color: var(--purple);
}
.custom-tab-img {
  text-align: center;
  padding: 3.5rem;
}

.custom-card {
  border-radius: 4rem;
  box-shadow: -40px 40px 70px rgba(125, 89, 195, 0.1);
  background-color: var(--white);
}
.custom-card .circle-icon {
  background: linear-gradient(180deg, #eceaf6 0%, rgba(237, 233, 247, 0) 100%);
}
textarea {
  resize: none;
}
.form-control {
  font-size: 14px;
  border-color: #c4c4c4;
  border-radius: 10px;
  padding: 13px 16px;
}
.form-control:focus {
  box-shadow: none;
  border-color: var(--purple);
}
.icon-input-wrap {
  position: relative;
}
.form-search {
  position: relative;
}
.form-search .icon-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
.form-search .form-control {
  padding-left: 50px;
}
.blog-dropdown .btn {
  height: 49px;
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  color: rgba(47, 49, 48, 0.5);
  font-weight: 500;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.blog-dropdown.show .btn {
  border-color: var(--purple);
}
.blog-dropdown .btn.dropdown-toggle::after {
  border-width: 5px;
  color: var(--purple);
}
.blog-dropdown .dropdown-menu {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.icon-input-wrap textarea {
  padding-right: 40px;
}
.blog-dropdown .dropdown-menu a {
  padding: 5px 15px;
}
.blog-dropdown .dropdown-menu a:active,
.blog-dropdown .dropdown-menu a:hover {
  color: var(--white);
  background-color: var(--purple);
}
.input-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--purple);
}
.contact-chip span {
  position: relative;
  background-color: #f2effa;
  font-size: 16px;
  font-weight: 500;
  color: var(--purple);
  padding: 8px 20px;
  margin-right: 10px;
  border-radius: 30px;
  cursor: pointer;
  display: inline-flex;
  transition: 0.3s all;
  margin-bottom: 10px;
}
.contact-chip input {
  opacity: 0;
  position: absolute;
}
.contact-chip input:checked + span {
  background-color: var(--purple);
  color: var(--white);
}
.contact-devider {
  position: relative;
}
.contact-devider span {
  padding: 0 17px;
  background-color: var(--white);
  display: inline-block;
  position: relative;
  z-index: 1;
}
.contact-devider:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  display: block;
  top: 50%;
  background-color: rgba(47, 49, 48, 0.25);
}

.bordered-text {
  /* font-family: var(--fontTaviraj); */
  font-size: 17rem;
  font-weight: 700;
  color: #f5f2fa;
  background: linear-gradient(180deg, #7c5ac7 0%, rgba(124, 90, 199, 0) 82.26%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 3px transparent;
}
.bordered-text.fw-96 {
  font-size: 9.6rem;
}
.bordered-text.maroon {
  background: linear-gradient(180deg, #9e2349 0%, rgba(158, 35, 74, 0) 70.26%);
  -webkit-background-clip: text;
}

.tooltip-inner {
  font-size: 16px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(27px);
  padding: 5px 10px;
  border-radius: 10px;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgba(255, 255, 255, 0.1);
}
.theme-shadow {
  filter: drop-shadow(10px 10px 30px rgba(124, 90, 199, 0.15));
}
.maroon-shadow {
  filter: drop-shadow(10px 10px 50px rgba(47, 49, 48, 0.1));
}
.scale-img {
  transform: scale(1);
}
.scale-transition {
  transition: 0.2s all;
}
.scale-img:hover {
  transform: scale(1.05);
}

/* INNER PAGES HEADER */
.inner-page-header .contact-us-btn,
.inner-page-header .contact-us-btn:hover {
  background-color: var(--purple);
}

.inner-page-header .login-btn,
.inner-page-header .login-btn:hover {
  border: 2px solid var(--purple);
  color: var(--purple);
  background-color: transparent;
}
/* INNER PAGES HEADER END */

.custom-slider {
  position: relative;
  padding-bottom: 60px;
}
.slick-list {
  transition: 0.3s height;
}
.custom-slider .slick-arrow {
  width: 60px;
  border-radius: 10px;
  display: inline-block;
  background: #ffffff;
  box-shadow: 5px 5px 30px rgba(166, 36, 75, 0.1);
  padding: 13px 20px;
  cursor: pointer;
  z-index: 1;
}
.custom-slider .slick-arrow.slick-prev,
.custom-slider .slick-arrow.slick-next {
  position: absolute;
  bottom: 0;
  right: 0;
}

.custom-slider .slick-arrow.slick-prev {
  right: auto;
  left: 0;
}
.custom-slider.arrows-center .slick-arrow.slick-next {
  right: calc(50% - 70px);
}
.custom-slider.arrows-center .slick-arrow.slick-prev {
  left: calc(50% - 70px);
}

.typanim {
  margin-left: -8px;
  display: flex;

  /* font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 4.8rem;
  position: relative;
  top: -10px; */
  /* height: 60px; */
}
.typanim .insSUPPORT {
  opacity: 0;
  pointer-events: none;
  font-size: 16px;
}

.workspaceHEAD {
  font-family: "Lexend" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  /* color: #939396 !important; */
}
.nopCON {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.nopCON img {
  max-width: 80vw;
}

/* TEMP HEADER FIX */
.header-secondary .contact-us-btn {
  border-color: transparent;
}
.header-none .inner-page-header{
  display: none !important;
}

@media screen and (min-width: 768px) {
  body.header-secondary:before {
    height: 89px;
  }
}

@media screen and (min-width: 992px) {
  body.header-secondary:before {
    height: 128px;
  }
}

@media screen and (min-width: 1040px) {
  body.header-secondary:before {
    height: 89px;
  }
}

@media screen and (min-width: 1100px) {
  body.header-secondary:before {
    height: 93px;
  }
}

@media screen and (min-width: 1300px) {
  body.header-secondary:before {
    height: 97px;
  }
}

@media screen and (min-width: 1400px) {
  body.header-secondary:before {
    height: 101px;
  }
}
/* TEMP HEADER FIX END */
