.retails-investors-hero {
  background: transparent url('https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/hero-bg.png') no-repeat top left;
  overflow: hidden;
}
.retails-investors-images {
  position: relative;
}
.retails-investors-hero-left {
  position: absolute;
  z-index: 1;
  width: 20%;
  left: 0;
  top: 10%;
  transform: rotate(-3deg);
}
.retails-investors-hero-left:hover {
  transform: rotate(-3deg) scale(1.05);
}
.retails-investors-hero-right {
  position: absolute;
  z-index: 1;
  left: 42%;
  bottom: 4%;
  width: 53.5%;
}
.liquid-assets,
.join-community {
  background: #f8f6fc url('https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/join-community-bg.jpg') no-repeat;
  background-position-x: 50%;
}
.join-community .custom-tab-img {
  background: linear-gradient(
    180deg,
    rgba(125, 89, 195, 0.2) 0%,
    rgba(125, 89, 195, 0) 100%
  );
}
.how-to-invest .custom-tab-link p:not(.big) {
  display: none;
}
.how-to-invest .custom-tab-link.active p {
  display: block;
}
.how-to-invest .custom-tab-img {
  background: transparent url('https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/how-to-invest-bg.png') no-repeat top left ;
  background-size: cover;
}
.investment-platform {
  background: transparent
    url('https://lilypads.s3.us-east-2.amazonaws.com/images/cover/investment-platform-bg.png') left center
    no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.investment-platform.retail-investor {
  background-image: url('https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/invest-plateform-bg.png');
}
.investment-platform-main-img {
  position: absolute;
  left: 50%;
  top: 10%;
}
.investment-platform .social-list a {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(158, 35, 73, 0.15);
}
.become-investor {
    background: #202221 url('https://lilypads.s3.us-east-2.amazonaws.com/images/retail-investor/become-investor-bg.jpg') no-repeat center ;
    background-size: cover;
}
.custom-portfolio-list {
  position: relative;
}
.custom-portfolio-list .bordered-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: -1;
}
@media only screen and (max-width: 991px) { 
  .custom-portfolio-list-wrap:not(:last-child) {
    margin-bottom: 8rem;
  }
}
@media only screen and (max-width: 575px) { 
  .custom-portfolio-list .bordered-text {
    left: auto;
    right: 0;
    transform: translate(0, -60%);
  }
}