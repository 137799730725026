.LPBolg-main {
    // background-color: red;
    padding: 0 50px;
    padding-top: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .BlogCon {
        max-width: 1720px;
        display: flex;
        flex-wrap: wrap;
        // background-color: #fff;

        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }

    .solo-BLOGCON {
        cursor: pointer;
        margin: 2rem;
        width: 410px;
        min-height: 456px;
        background: #FFFFFF;
        // background: red;
        box-shadow: -20px 20px 40px rgba(166, 36, 75, 0.1);
        border-radius: 20px;
        padding: 2rem;
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.03);
        }

        .BLOG-content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            /* number of lines to show */
            -webkit-box-orient: vertical;
            margin-bottom: 20px;
        }

        img {
            height: 193px;
            object-fit: cover;
            width: 100%;
            border-radius: 10px;
        }




        .BLOG-Tags {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            margin-bottom: 10px;

            p {
                border-radius: 5px;
                margin-right: 5px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 21px;
                /* identical to box height, or 175% */
                background: #7c5ac72f;
                padding: 2px 10px;
                color: #7C5AC7;
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            /* or 175% */


            color: #202221;

            &.tp {
                font-weight: 700;
                font-size: 14px;
            }
        }

        .Blog-BOTM {
            display: flex;

            align-items: center;
            justify-content: space-between;

            p {
                &.lp {
                    font-weight: 600;
                }

                &.rp {
                    opacity: 0.5;
                }
            }
        }
    }
}

.mlc {
    min-height: 100vh;
}

.LPBolg-main-IND {
    padding: 0 150px;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    margin-bottom: 100px;

    .BLOG-Tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        margin-bottom: 10px;

        p {
            border-radius: 5px;
            margin-right: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            /* identical to box height, or 175% */
            background: #7c5ac72f;
            padding: 2px 10px;
            color: #7C5AC7;
        }
    }

    .NDTitle {

        // background-color: red;
    }

    .NDcontent {

        img {
            width: 100%;

            height: 460px;
            object-fit: cover;
            border-radius: 20px;
        }

        p {
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            /* or 169% */


            color: #202221;
        }
    }


    h2 {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height, or 150% */


        color: #202221;
    }

    h3 {

        font-family: 'Lexend';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */


        color: #202221;
    }
}

@media only screen and (max-width: 600px) {
    .LPBolg-main-IND {
        padding: 30px;
        padding-top: 100px;
    }

    .LPBolg-main {
        .solo-BLOGCON {
            width: 350px;
        }
    }
}

@media only screen and (min-width: 600px) {
    .related-blog-slider.custom-slider.arrows-center {

        .slick-arrow.slick-prev,
        .slick-arrow.slick-next {
            display: block;
            top: 0;
            bottom: auto;
            transform: translateY(-100%);
            left: auto;
            right: 0;
        }

        .slick-arrow.slick-prev {
            right: 80px;
        }
    }
}

.paginationOwn {
    list-style: none;
    display: flex;
    padding: 5px;
    // background-color: #fff;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;

    .pprevlcc {}

    li {
        &.selected {
            a {
                color: #FFFFFF;
                background-color: #9e2349;
                padding: 5px 10px;
                border-radius: 50%;
            }
        }
    }

    a {
        color: #9e2349;
        padding: 5px;

        &:hover {
            cursor: pointer;
        }
    }
}