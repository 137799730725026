.sectionTwoCL {
  min-height: 100vh;
  // justify-content: center;
  // width: 60vw;
  margin: auto;
  padding-bottom: 5vh;
  // text-align: justify;
  .searchBOX {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    .inputHolder {
      width: 100%;
      height: 60px;
      background: #00141f;
      align-items: center;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;

      background: #ffffff;
      border: 1px solid rgba(44, 42, 49, 0.2);
      border-radius: 16px;

      padding: 0 10px;
      position: relative;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .SearchBtn {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
      height: 44px;

      /* product white */

      background: #ffffff;
      box-shadow: 6px 6px 16px rgba(124, 90, 199, 0.3);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }

    input {
      background: #00141f00;
      border: none;
      outline: none;
      height: 100%;
      width: 85%;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      display: flex;
      align-items: center;

      /* Text color */

      color: #2f3130;

      padding-left: 15px;
      /* identical to box height, or 150% */

      color: #7f898f;
    }
  }
  .sectionTwoCLinfo {
    padding: 0 0;
    padding-top: 30px;
    padding-bottom: 20px;
    background: transparent url("../../assets/images/career-banner.jpg")
      no-repeat center;
    background-size: cover;
    height: 301px;
    display: flex;
    align-items: flex-end;
    h2 {
      margin: 0;
      padding: 0;
      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height, or 150% */

      text-align: center;
      text-transform: capitalize;
      color: #fff;
      margin-bottom: 10px;
    }

    h4 {
      margin: 0;
      padding: 0;
      font-family: Lexend;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      /* or 183% */

      text-align: center;

      color: #ffffff;
      width: 850px;
    }
  }

  .jobList {
    display: flex;
    flex-direction: column;

    .jobSContainer {
      padding: 3vh 1vw;
      border: 1px solid rgba(233, 225, 225, 0);
      border-radius: 8px;
      transition: all 0.3s ease;
      box-shadow: 8px 8px 24px rgb(124 90 199 / 12%);
      border-radius: 30px;
      margin-bottom: 20px;
      // &:hover {
      //   border: 1px solid rgb(233, 225, 225);
      //   transform: scale(1.01);
      //   cursor: pointer;
      //   box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      //     rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      //     rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      // }
    }

    .top {
      display: flex;
      // flex-direction: column;
      align-items: space-between;
      justify-content: space-between;
      padding-bottom: 1.5vh;

      .applyBtn {
        width: 140px;
        height: 52px;

        background: rgba(124, 90, 199, 0.12);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        z-index: 1;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;

        /* Primary 1 */

        color: #7c5ac7;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(0.9);
        }
      }

      .jobdataSet {
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        // justify-content: center;
      }
      .dsCon {
        margin-top: 10px;
        align-items: center;
        display: flex;
        svg {
          margin-top: -10px;
        }
        h5 {
          margin-left: 8px;
          margin-right: 15px;
          font-size: 14px;
          line-height: 140%;
          /* identical to box height, or 20px */

          /* Text color */

          color: #2f3130;
          font-family: "Lexend";
          font-style: normal;
          opacity: 0.5;

          /* Inside auto layout */

          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
      h4 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        /* identical to box height, or 26px */

        display: flex;
        align-items: center;
        letter-spacing: 0.4px;

        /* Text color */

        color: #2f3130;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    .bottom {
      overflow: hidden;
      text-overflow: ellipsis;

      // height: 65px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;

      p {
        margin: 0;
        padding: 0;
        opacity: 0.5;
        color: rgb(0, 0, 0);

        &:nth-child(2) {
          display: none;
        }
      }

      h2 {
        display: none;
      }

      h3 {
        display: none;
      }
    }

    .bottomMob {
      display: none;
      padding: 0;
      margin: 0;

      font-family: Archivo;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      /* identical to box height */

      text-transform: uppercase;

      color: #219ebc;

      h5 {
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1195px) {
  .sectionTwoCL .sectionTwoCLinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h4 {
      width: 95%;
    }
  }
}

@media only screen and (max-width: 790px) {
  .sectionTwoCL {
    padding-top: 0vh;
    // width: 80vw;
  }
}

@media only screen and (max-width: 500px) {
  .sectionTwoCL {
    padding-top: 0vh;
  }
  .sectionTwoCL .searchBOX {
    flex-direction: column;
    // background-color: #fff;
    width: 100%;
    .inputHolder {
      width: 100%;
    }
    .SearchBtn {
      // margin-left: 0;
      // width: 100%;
      // margin-top: 15px;
    }
  }
  .sectionTwoCL .jobList .top {
    text-align: left;
    padding-bottom: 9px;
    flex-direction: column;
  }

  .sectionTwoCL .sectionTwoCLinfo {
    // padding-top: 0px;
    // margin-top: -40px;
    height: 200px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .sectionTwoCL .jobList .jobSContainer {
    padding: 15px;
  }

  .sectionTwoCL .sectionTwoCLinfo h4 {
    // text-align: justify;
  }

  .sectionTwoCL .jobList .bottomMob {
    margin-top: 15px;
    // display: flex;
  }

  .sectionTwoCL .jobList .top h5 {
    // display: none;
  }
  .sectionTwoCL .jobList .top .applyBtn {
    width: 120px;
    height: 43px;
    border-radius: 7px;
    margin: 10px 0;
  }
}

.filter-card {
  padding: 17px;
  background: #ffffff;
  border: 1px solid rgba(44, 42, 49, 0.2);
  border-radius: 16px;
  margin: 20px 0 12px;
}
.filter-card .contact-chip span {
  padding: 8px 16px 8px 8px;
  color: rgba(124, 90, 199, 0.5);
}
.filter-check-icn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #d8ceef;
  border-radius: 50%;
  margin-right: 4px;
}
.filter-check-icn svg {
  width: 16px;
  height: 16px;
}
.contact-chip input:checked + span .filter-check-icn {
  background: #fff;
}
