.LoaderMain{
    position: fixed;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    .LoaderCircle{
        width: 2.3vw;
        height: 2.3vw;
        border-radius: 50%;
        // background-color: #FB8500;
        background-color: #7d59c3;
        animation-name: spin;
        animation-duration: 700ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
}

@keyframes spin {
    0% {
        transform:scale(0.3);
        opacity: 0.2;
    }
    85%{
        transform:scale(1);
        opacity: 1;
    }
    100% {
        // transform:scale(1);
        opacity: 0;
    }
}

@media only screen and (max-width: 500px) {
    .LoaderMain{
       
        
        .LoaderCircle{
            width: 35px;
            height: 35px;
         
        }
    }
    
}