@media only screen and (max-width: 1400px) {
  html {
    font-size: 9px;
  }
  body {
    font-size: 14px;
  }
  .h5,
  h5 {
    font-size: 22px;
  }

  .h5.small,
  h5.small {
    font-size: 20px;
  }

  .h6,
  h6 {
    font-size: 18px;
  }

  p.big {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  p.small {
    font-size: 12px;
  }
  .btn {
    height: 55px;
  }
  .btn.bold {
    font-size: 14px;
  }
  .fdt-accordion .accordion-button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1299px) {
  html {
    font-size: 8px;
  } 
  .btn {
    height: 50px;
    padding: 8px 2.7rem;
  }
}
@media only screen and (max-width: 1099px) {
  html {
    font-size: 7px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }

  .h5.small,
  h5.small {
    font-size: 18px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) { 
  .img-reset-mob {
    position: static;
    transform: none;
    max-width: 90%;
    width: 90%;
    display: block;
    margin: 0 auto;
  }
  .btn.contact-us-btn,
  .btn.contact-us-btn:hover {
    background-color: var(--purple);
  }

  .btn.login-btn,
  .btn.login-btn:hover {
    border: 2px solid var(--purple);
    color: var(--purple);
    background-color: transparent;
  }
}
@media only screen and (max-width: 767px) {
  html {
    font-size: 6px;
  }
  .h1, h1 {
    font-size: 6.3rem;
  }
  .h5,
  h5 {
    font-size: 16px;
  }

  .h5.small,
  h5.small {
    font-size: 16px;
  }

  .h6,
  h6 {
    font-size: 15px;
  }
  p.big {
    font-size: 14px;
  }
  .app-main {
    margin-top: -75px;
  }
}
@media only screen and (max-width: 575px) {
  .LPBolg-main {
    padding-left: 15px;
    padding-right: 15px;
  }
}