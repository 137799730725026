.investmain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 73%;
    margin: auto;
    align-items: center;
}
.InvestApp{
    
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}
.investTitle{
    font-family: 'Lexend';
font-style: normal;
font-weight: 600;
font-size: 18px;

}
.DataOriented{
    font-family: 'DM Serif Display';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 49px;
}
.DataOrientedpara{
    font-family: 'Lexend';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
}
.DataOrientedmain{
   align-items: center;
   margin-bottom: 100px;
}
.Dataorientcard{
    margin: auto;
    display: block;
    width: 216px;
    margin-top: -116px;
    height: 216px;
}
.StreamlinedInvestmentProcesses{
    font-family: 'DM Serif Display';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 49px;
width: 75%;
margin: auto;
padding-top: 40px;
}
.Custombuilt{
    font-family: 'DM Serif Display';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 49px;
}
.datacentricapproach{
    margin: auto;
    margin-top: 39px;
    margin-bottom: -450px;
}
@media screen and (max-width:1200px) {
    .datacentricapproach{
        margin: auto;
        margin-top: 39px;
        margin-bottom: 1px;
        width: 300px;
    }
    .discoverfeatureresponsiveness{
        display: grid;
    }
}
.Howtoinvest{
    display: flex;
  
   text-align: left;
}
.img{
    position: absolute;
    right: 0;
    top: 10vh;
}
.investspace-top {
    padding-top: 18rem;
}