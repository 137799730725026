.schedule-slider {
  background: rgb(125, 89, 195);
  background: linear-gradient(
    0deg,
    rgba(125, 89, 195, 0) 0%,
    rgba(125, 89, 195, 0.2) 100%
  );
  max-width: 410px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}

.schedule-img {
  margin-bottom: 20px;
}

.schedule-img img {
  border-radius: 30px;
  max-height: 432px;
  width: 100%;
}

.schedule-quote {
  position: relative;
  margin-bottom: 20px;
}

.schedule-quote::before {
  content: url('https://lilypads.s3.us-east-2.amazonaws.com/images/icons/quote-start.svg');
  width: 20px;
  height: 12px;
  margin-right: 8px;
}

.schedule-quote::after {
  content: url('https://lilypads.s3.us-east-2.amazonaws.com/images/icons/quote-end.svg');
  width: 20px;
  height: 12px;
  margin-left: 8px;
}

.schedule-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-arrows .custom-arrow {
  margin: 0 15px;
}

.custom-arrow {
  width: 60px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 30px 0 rgba(166, 36, 75, 0.1);
  background-color: var(--white);
  border: none;
  transition: 0.3s all;
}

.custom-arrow:hover {
  background-color: #f5f5f5;
}

/* RESPONSIVE */
@media screen and (max-width: 575px) {
  .follow-us-section .slick-slide {
    padding: 0 5px;
  }
}

/* RESPONSIVE END */

.SLIDERCALENDER-con {
  display: flex;
  /* background-color: red; */
  width: 100%;
}

.SLIDERCALENDER-con .SLIDER-L {
  width: 50%;
  &.thomas {
    display: flex;
    align-items: center;
    justify-content: center;
    .schedule-item {
      min-width: 300px;
    }
  }
  /* background-color: #fff; */
}

.SLIDERCALENDER-con .SLIDER-R {
  /* background-color: blue; */
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.SLIDERCALENDER-con .SLIDER-R {
  overflow-y: hidden;

  .calendly-inline-widget {
    overflow-y: hidden;
    .simplebar-content-wrapper {
      display: none !important;
    }
  }
}
