.LPmanageSection4{
    background: #202221 !important;
    color: #fff;
}
.collectingcapital{
   display: flex;
   justify-content: center;
   background-image: url('../../assets/images/Manage/Mask\ Group\ \(19\).png');
   background-repeat: no-repeat;
    z-index: 1;
    background-position-x:left;
   
}
.discover2ndbg{
    background-image: url('../../assets/images/Manage/Mask\ Group\ \(20\).png');
   background-repeat: no-repeat;
    z-index: 1;
    background-position-x:right;
    background-position-y: bottom;
}
.invest3rdbg{
    background-image: url('../../assets/images/Manage/Mask\ Group\ \(20\).png');
    background-repeat: no-repeat;
     z-index: 1;
     background-position-x:right;
     background-position-y: -200px;
}
@media screen and (max-width:768px) {
    .collectingcapita{
        width: auto;
    }
}
.Investmentmanagementcard{
width :329px;
}
.Investmentmanagementfullcard{
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    
}
.Legalcards{
    display: flex;
    justify-content: center;
   
}
.legalone{
    background: #FFFFFF;
box-shadow: -40px 40px 70px rgba(166, 36, 75, 0.05);
border-radius: 20px;
width: 190px;
height: 184px;
align-items: center;
margin-left: 15px;
margin-right: 15px;

}
.Legalcardsone{
    margin: auto;
    display: block;
   
}
.legalheading{
    margin: auto;
    display: table;
}
.lettericon{
    padding: 30px;
}
@media screen and (max-width:1024px) {
    .Legalcards{
        display: grid;
    }
}
.mutualgoals{
    /* margin-top: 100px; */
    margin-bottom: -189px;
}
.managepara{
    font-family: 'Lexend';
font-style: normal;
font-weight: 400;
font-size: 16px;
}
.collectingcapitalimg{
    margin-bottom: 80px;
}
@media screen and (max-width:1024px) {
   
    .collectingcapitalimg, .cashflow{
        width: 320px;
         margin: auto;
    }
}
@media screen and (max-width:1300px) {
.mutualgoals{
    margin-top:10px;
    margin-bottom: 0px; 
    width: 302px;
}
.Investmentmanagementfullcard{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
}

}
.StreamlineDaytoDay{
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    
    margin: auto;
    padding-top: 40px;
}
/* .cashflowimg{
    display: flex;
    justify-content: center;
} */
.professionalexperience{
    display: flex;
    margin-top: 50px;
}
@media screen and (max-width:805px) {
    .professionalexperience{
        display: grid;
    }
}
.Guaranteesecurity{
    margin-bottom: 25px;
}
.importantdocuments{
  padding-bottom: 100px;
}