.cityskyliner-cat {
  text-align: center;
}
.cityskyliner-icon {
  position: relative;
  z-index: 1;
  width: 9rem;
  height: 9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #eaeaea 0%, #ffffff 100%);
  box-shadow: 36px 36px 90px 0px #c6c6c6e5, -1px -1px 2px 0px #c6c6c680 inset;
  border-radius: 50%;
}
.cityskyliner-icon img {
  max-width: 4.5rem;
}
.cityskyliner {
  position: relative;
}
.cityskyliner-cat {
  position: absolute;
  height: 100%;
  top: 10%;
  left: calc(12.5% - 45px);
}
.cityskyliner-cat-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.cityskyliner-cat-inner:after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 100px);
  left: 50%;
  border-left: 1px dashed #7c5ac7;
  bottom: 0;
}
.cityskyliner-cat p {
  text-transform: uppercase;
}
.cityskyliner-cat-1 {
  top: 10%;
  left: calc(12.5% - 45px);
}
.cityskyliner-cat-2 {
  top: 20%;
  left: calc(25% - 45px);
}
.cityskyliner-cat-3 {
  top: 0;
  left: calc(37.5% - 45px);
}
.cityskyliner-cat-4 {
  top: -10%;
  left: calc(50% - 45px);
}
.cityskyliner-cat-5 {
  top: -25%;
  left: calc(62.5% - 45px);
}
.cityskyliner-cat-6 {
  top: -5%;
  left: calc(75% - 45px);
}
.cityskyliner-cat-7 {
  top: -5%;
  left: calc(87.5% - 45px);
}
.img-cityskyliner {
  position: relative;
}
.invest-card-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.invest-card {
  width: 15rem;
  height: 19rem;
  background: linear-gradient(135deg, #ffffff 0%, #eaeaea 100%);
  box-shadow: 25px 25px 50px rgba(203, 203, 203, 0.2),
    -25px -25px 50px rgba(203, 203, 203, 0.2),
    25px -25px 50px rgba(255, 255, 255, 0.9),
    -25px 25px 63px rgba(203, 203, 203, 0.9),
    inset -1px 1px 2px rgba(255, 255, 255, 0.3),
    inset 1px -1px 2px rgba(203, 203, 203, 0.5);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: 0.3s all;
  position: relative;
}
.invest-card-slider .invest-card {
  margin: 50px auto 30px;
  box-shadow: none !important;
}
.invest-card-block-img {
  max-width: 11rem;
}
.invest-card-img {
  max-width: 9rem;
}
.invest-text {
  background: #7c5ac7;
  box-shadow: 6px 6px 15px rgba(124, 90, 199, 0.2);
  border-radius: 25px;
  transform: rotate(-5deg) translate(50%, 50%);
  padding: 6px 20px;
  position: absolute;
  margin: 0;
  color: var(--white);
  right: 50%;
  transition: 0.3s all;
}
.ai-algorithm {
  position: relative;
  margin-bottom: 50rem;
}
.invest-card-main {
  position: absolute;
  width: 100%;
  top: 60%;
}
.invest-card-2,
.invest-card-5 {
  margin-top: 18.5rem;
}
.invest-card-3,
.invest-card-4 {
  margin-top: 39rem;
}
.invest-card.invest-right {
  background: linear-gradient(135deg, #ffffff 0%, #eaeaea 100%);
  box-shadow: -25px 25px 50px rgba(203, 203, 203, 0.2),
    25px -25px 50px rgba(203, 203, 203, 0.2),
    -25px -25px 50px rgba(255, 255, 255, 0.9),
    25px 25px 63px rgba(203, 203, 203, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(203, 203, 203, 0.5);
}
.invest-card.invest-right p {
  transform: rotate(5deg) translate(50%, 50%);
}
.ai-algorithm-content {
  position: absolute;
  top: 8rem;
  right: calc(50% + 25rem);
  padding: 3rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 1rem 1rem 5rem rgba(124, 90, 199, 0.12);
  backdrop-filter: blur(10px);
  border-radius: 3rem;
  z-index: 2;
  max-width: 36rem;
}
.ai-algorithm-content.top-to {
  position: absolute;
  right: auto;
  left: calc(50% + 15rem);
  text-align: center;
  max-width: 43rem;
  top: -50px;
}
.ai-algorithm-content.end {
  top: auto;
  bottom: -45rem;
  left: calc(50% + 25rem);
  right: auto;
}
.invest-card:hover {
  box-shadow: 0px 0px 50px rgb(203 203 203 / 20%),
    0px 0px 50px rgb(203 203 203 / 20%), 0px 0px 50px rgb(255 255 255 / 90%),
    0px 0px 63px rgb(203 203 203 / 90%),
    inset -1px 1px 2px rgb(255 255 255 / 30%),
    inset 1px -1px 2px rgb(203 203 203 / 50%);
}
.invest-card:hover p {
  transform: rotate(0deg) translate(50%, 50%);
}

.ripple-wrap {
  max-width: 50%;
  margin: 0 auto;
  width: 600px;
  height: 600px;
}

.ripple {
  border: 1px solid black;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.ai-block-section {
  position: relative;
  z-index: 1;
  padding-top: 400px;
}

.ai-block-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  border-left: 2px dashed white;
}
.ai-block-line-1-0 {
  border-left: 2px dashed #8666cb;
}
.ai-block-line-1 {
  height: 495px;
  transform: translateX(-450px);
}
.ai-block-line-1-1 {
  top: 495px;
  height: 435px;
  transform: rotate(334.9deg) translate(-316px, -170px);
}
.ai-block-line-2 {
  height: 410px;
  transform: translateX(-250px);
}
.ai-block-line-2-2 {
  top: 440px;
  height: 115px;
  transform: rotate(115.9deg) translate(31px, 203px);
}
.ai-block-line-3 {
  height: 370px;
  transform: translateX(-75px);
}
.ai-block-line-3-3 {
  top: 370px;
  height: 72px;
  transform: rotate(115.9deg) translate(0px, 47px);
}
.ai-block-line-4 {
  height: 455px;
  transform: translateX(200px);
}
.ai-block-line-4-4 {
  top: 455px;
  height: 120px;
  transform: rotate(231deg) translate(-80px, 134px);
}
.ai-block-line-5 {
  height: 850px;
  transform: translateX(300px);
}
.ai-block-line-5-5 {
  top: 790px;
  height: 77px;
  transform: rotate(57deg) translate(182px, -204px);
}
.ai-block-line-6 {
  height: 895px;
  transform: translateX(450px);
}
.ai-block-line-6-6 {
  top: 984px;
  height: 187px;
  transform: rotate(53deg) translate(124px, -375px);
}

.ai-block-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/ai/ai-box-gradient.png") top center
    no-repeat;
  background-size: 100% auto;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(136, 105, 204, 1) 100%
  ); */
}

.ai-block-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent url("../../assets/images/ai/wave-2.png") center center
    no-repeat;
  background-size: 100% auto;
}

.ai-block-wrap > svg {
  max-width: 600px;
  margin: 0 auto;
}

[id^="blockpath"] {
  fill: none;
  stroke: none;
}
.superiority-card {
  position: relative;
}
.superiority-card .bordered-text {
  color: #f5f2fa;
  background: linear-gradient(180deg, #2f3130 0%, rgba(47, 49, 48, 0) 86.98%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 3px transparent;
  position: absolute;
  top: 0;
  transform: translateY(-35%);
  z-index: -1;
}

.pulse-circle-sm,
.pulse-circle-md,
.pulse-circle-lg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.7);
  -ms-transform: translate(-50%, -50%) scale(0.7);
  transform: translate(-50%, -50%) scale(0.7);
  border: 1px solid #edf0f2;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 0%,
    #d1c5eb 91.39%,
    #7c5ac7 100%
  );
  opacity: 0;
  -webkit-box-shadow: 0px 0px 100px rgb(29 161 242 / 5%);
  box-shadow: 0px 0px 100px rgb(29 161 242 / 5%);
  transition: 0.3s all;
}

.pulse-circle-sm {
  z-index: -1;
  width: 62rem;
  height: 62rem;
  border-radius: 1000px;
}

.pulse-circle-md {
  z-index: -2;
  width: 84rem;
  height: 84rem;
  border-radius: 1000px;
}

.pulse-circle-lg {
  z-index: -3;
  width: 110rem;
  height: 110rem;
  border-radius: 1200px;
}

.pulse-rate {
  -webkit-animation: pulse-rate infinite linear 2s;
  animation: pulse-rate infinite linear 2s;
}

.pulse-delay-2 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.pulse-delay-2-25 {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.pulse-delay-2-50 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes pulse-rate {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(0.9);
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.12;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0;
  }
}

@keyframes pulse-rate {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(0.9);
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.12;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(237 240 242 / 100%);
    box-shadow: 0 0 0 0 rgb(237 240 242 / 100%);
    opacity: 0.12;
  }

  60% {
    -webkit-box-shadow: 0px 0px 400px rgb(237 240 242 / 0);
    box-shadow: 0px 0px 400px rgb(237 240 242 / 0);
    opacity: 0.5;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgb(237 240 242 / 0);
    box-shadow: 0 0 0 0 rgb(237 240 242 / 0);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(237 240 242 / 100%);
    box-shadow: 0 0 0 0 rgb(237 240 242 / 100%);
    opacity: 0.12;
  }

  60% {
    -webkit-box-shadow: 0px 0px 400px rgb(237 240 242 / 0);
    box-shadow: 0px 0px 400px rgb(237 240 242 / 0);
    opacity: 0.5;
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgb(237 240 242 / 0);
    box-shadow: 0 0 0 0 rgb(237 240 242 / 0);
    opacity: 0;
  }
}
@media screen and (max-width: 1445px) {
  .ai-block-wrap,
  .ai-block-section::before {
    background-size: unset;
  }
}
@media screen and (max-width: 991px) {
  .ai-block-section {
    margin-top: -25px;
  }

  .ai-algorithm-content.top-to {
    left: 20%;
    top: -200px;
    max-width: none;
  }
  .ai-algorithm-content p.big {
    font-weight: 700;
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .ai-algorithm-content {
    position: static;
    max-width: 100%;
    margin: 30px 15px;
  }
  .invest-card-main {
    position: static;
  }
  .invest-card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .invest-card-3,
  .invest-card-4,
  .invest-card-2,
  .invest-card-5 {
    margin-top: 0;
  }
  .ai-algorithm {
    margin-bottom: 35px;
  }
  .invest-card {
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
    height: 30rem;
  }
  .img-ai {
    max-width: 50rem;
  }
  .smart-contract-note {
    margin-top: 0;
  }
  .img-mob-cityskyliner {
    margin-top: -75px;
  }
  .invest-card-img {
    max-width: 15rem;
  }
  .ai-block-line-1 {
    height: 895px;
    transform: translateX(-132px);
  }
  .ai-block-line-1-0 {
    display: none;
  }
  .ai-block-line-2 {
    height: 464px;
    transform: none;
    margin-left: -71px;
  }
  .ai-block-line-3 {
    height: 400px;
    transform: none;
    margin-left: -4px;
  }
  .ai-block-line-4 {
    height: 530px;
    transform: translateX(54px);
  }
  .ai-block-line-5 {
    height: 890px;
    transform: translateX(147px);
  }
  .superiority-card .bordered-text {
    background: linear-gradient(
      180deg,
      #9e2349 0%,
      rgba(158, 35, 74, 0) 70.26%
    );
    -webkit-background-clip: text;
  }
  .ai-block-wrap svg {
    max-width: 300px;
    width: 100%;
  }
  .ai-algorithm-content.end,
  .ai-algorithm-content.top {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .ai-algorithm-content.end {
    margin-top: 0;
  }
  .technological-superiority h2 {
    font-size: 26px;
  }

  .invest-right p {
    bottom: 8px;
  }
}
