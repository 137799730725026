.bc-page {
  background-color: var(--bgDark);
}

.phone-block-section {
  padding-top: 40px;
}

.blockchain-cat {
  position: relative;
}
.blockchain-cat.blockchain-cat-left {
  text-align: right;
}
.blockchain-cat.blockchain-cat-right {
  text-align: left;
}
.blockchain-cat .blockchain-round {
  width: 30rem;
  height: 30rem;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.blockchain-cat .blockchain-round:before {
  content: "";
  opacity: 0.1;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(90deg, #7c5ac7 0%, rgba(124, 90, 199, 0) 100%);
}
.blockchain-cat .blockchain-round img {
  max-width: 20rem;
  max-height: 15rem;
}
.blockchain-cat.blockchain-cat-left .blockchain-round:before {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.blockchain-cat p {
  max-width: 200px;
  margin: 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
}
.blockchain-cat p {
  left: 25rem;
}
.blockchain-cat.blockchain-cat-left p {
  right: 25rem;
  left: auto;
}
.smart-contract {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.smart-contract .smart-contract-bg {
  width: 100%;
  object-fit: cover;
  animation: leftAndRight 5s forwards infinite ease-out;
}
.smart-contract-content {
  max-width: 40%;
  display: inline-flex;
  flex-direction: column;
  border-radius: 50%;
}
.smart-contract-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}
.smart-contract-note {
  position: relative;
  margin-top: -10rem;
}
.smart-contract-note img {
  max-width: 40rem;
}
.smart-contract-releases {
  background-image: url("/src/assets/images/backgrounds/purple-grid-bg.svg") !important;
  background-position: center top !important;
  background-color: var(--bgDark) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.smart-contract-releases p {
  margin: 0 auto;
  max-width: 250px;
  min-height: 102px;
  flex-direction: column;
  justify-content: center;
}

.sc-svg,
.sc-plot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.sc-svg > svg,
.sc-plot > svg {
  object-fit: contain;
}

.sc-svg {
  width: 250px;
  height: 250px;
}

.sc-svg > svg {
  width: 100%;
  height: 100%;
}

.sc-plot {
  width: 300px;
}

.sc-plot > svg {
  width: 100%;
  max-width: 100%;
}

#leftPlotSvg,
#rightPlotSvg {
  display: none;
  opacity: 0;
  visibility: hidden;
}

#sc-text-1 {
  display: block;
  opacity: 1;
  visibility: visible;
}

.sc-contract-mid-text {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.invest-card-main.blockchain-invest {
  position: relative;
  margin-top: -11.5rem;
}
.invest-card-main.blockchain-invest .invest-card {
  background: linear-gradient(135deg, #ffffff 0%, #eaeaea 100%);
  box-shadow: 25px 25px 50px rgba(47, 49, 48, 0.2),
    -25px -25px 50px rgba(47, 49, 48, 0.2),
    inset -1px 1px 2px rgba(255, 255, 255, 0.3),
    inset 1px -1px 2px rgba(203, 203, 203, 0.5);
  border-radius: 24px;
  position: relative;
}
.invest-card-main.blockchain-invest .invest-card-block-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 17rem;
}
.invest-card-big {
  width: 24rem;
  height: 30rem;
  box-shadow: none !important;
  margin: 0 auto;
  margin-top: 5rem;
}
.invest-card-big .invest-card-img {
  max-width: 17rem;
  max-height: 30rem;
  margin-bottom: -35px;
  transform: scale(1);
  transition: 0.3s all;
}
.invest-card-big:hover .invest-card-img {
  transform: scale(1.05);
}
.securities-img-wrapper {
  position: relative;
  max-width: 480px;
  margin: 0 auto;
  margin-left: -8%;
  padding-right: 8%;
}
.securities-img-wrapper .chart {
  filter: drop-shadow(-10.9412px 10.9412px 21.8824px rgba(185, 185, 185, 0.2));
  transform: scale(1);
  transition: 0.3s transform;
}
.securities-img-wrapper .chart:hover {
  transform: scale(1.1);
}
.securities-img-wrapper .chart1 {
  position: absolute;
  top: 18%;
  left: -1%;
  width: 35%;
  height: auto;
}
.securities-img-wrapper .chart2 {
  position: absolute;
  left: 40%;
  top: 25%;
  width: 24.5%;
}
.securities-img-wrapper .chart3 {
  position: absolute;
  bottom: 29%;
  left: 7%;
  width: 32%;
}
.securities-img-wrapper .chart4 {
  position: absolute;
  bottom: 4%;
  left: 30%;
  width: 37.5%;
}

.lilypads-securities {
  background-color: var(--white);
}

.phone-block {
  max-width: 400px;
  margin: 0 auto;
}

.phone-block svg {
  width: 100%;
}

.phone-building-img {
  display: flex;
  justify-content: center;
}

@keyframes leftAndRight {
  0% {
    left: -20px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: -20px;
  }
}

@media screen and (max-width: 1279px) {
  .phone-block {
    max-width: 350px;
  }

  .phone-building-img {
    max-width: 110px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1199px) {
  .phone-block {
    max-width: 300px;
  }
}

@media screen and (max-width: 1023px) {
  .sc-svg {
    width: 180px;
    height: 180px;
  }

  .sc-plot {
    width: 180px;
  }

  .coinstack-wrap {
    max-width: 120px;
    margin: 0 auto;
  }

  .coinstack-wrap svg {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .smart-contract-note {
    margin-top: -6rem;
  }

  .sc-svg,
  .sc-plot {
    width: 100%;
    max-width: 200px;
  }

  .phone-block {
    max-width: 180px;
  }

  .phone-building-img {
    max-width: 90px;
  }

  .securities-img-wrapper {
    margin-left: auto;
    padding-right: unset;
    max-width: 350px;
  }
}
@media screen and (max-width: 767px) {
  .invest-card-main.blockchain-invest .invest-card {
    margin-bottom: 50px;
  }
  .smart-contract-content {
    padding: 4rem;
  }
  .smart-contract .smart-contract-bg {
    object-fit: cover;
  }
  .blockchain-cat {
    margin-bottom: 35px;
  }
  .blockchain-cat.blockchain-cat-left p {
    right: 10px;
    left: auto;
    transform: none;
    top: 75%;
    text-align: left;
  }
  .blockchain-cat.blockchain-cat-right p {
    left: 10px;
    transform: none;
    top: 75%;
    text-align: right;
  }

  .blockchain-cat p {
    height: auto;
  }

  .sc-svg,
  .sc-plot {
    width: 150px;
    height: 150px;
  }

  .coinstack-wrap {
    max-width: 100px;
    height: 100px;
  }

  .coinstack-wrap svg {
    width: 100%;
    height: 100%;
  }

  .securities-img-wrapper {
    max-width: 250px;
  }
}

@media screen and (max-width: 575px) {
  .phone-block {
    max-width: 150px;
  }

  .smart-contract-content {
    max-width: 100%;
  }

  .smart-contract-releases {
    padding-bottom: 0;
  }

  .smart-contract-releases > .space-bottom.container {
    padding-bottom: 0;
  }

  .sc-svg,
  .sc-plot {
    width: 100px;
    height: 100px;
  }
}
@media screen and (max-width: 470px) {
  .blockchain-cat .blockchain-round {
    width: 250px;
    height: 250px;
  }
  .blockchain-cat {
    margin-top: 15px;
  }
  .blockchain-cat.blockchain-cat-left .blockchain-round {
    margin-left: -80px;
  }
  .lilypads-securities h2 {
    font-size: 26px;
  }
  .lilypads-securities h2 p {
    margin-bottom: 20px;
  }
  .lilypads-securities p {
    line-height: 1.8;
  }
  .securities-img-wrapper-mob {
    margin-bottom: -40px;
  }

  .phone-block {
    max-width: 120px;
  }
}

@media screen and (max-width: 413px) {
  .phone-block {
    max-width: 100px;
  }

  .blockchain-cat p {
    font-size: 12px;
  }
}
