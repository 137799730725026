footer {
  background: #202221;
  padding: 5rem 0;
}

.social-list {
  display: flex;
  margin-top: 4rem;
  flex-wrap: wrap;
}
.social-list a {
  width: 50px;
  height: 50px;
  display: inline-flex;
  background-color: var(--white);
  border-radius: 50%;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  color: var(--purple);
}
.footer-text,
.footer-head {
  color: var(--white);
  font-family: var(--fontLexend);
}
.footer-head {
  margin-bottom: 10px;
  font-weight: 600;
}
.footer-text {
  opacity: 0.6;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 12px;
}
.footer-links {
  display: flex;
  flex-direction: column;
}
.footer-links a {
  text-decoration: none;
  margin-bottom: 8px;
  transition: 0.3s all;
}
.footer-links a:hover {
  color: var(--white);
  opacity: 1;
}
.footer-subscribe-wrapper {
  margin-bottom: 5rem;
}
.footer-subscribe {
  position: relative;
}
.footer-subscribe .form-control {
  font-size: 14px;
  background-color: transparent;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 55px;
  padding-left: 15px;
  border-radius: 15px;
  color: var(--white);
}
.footer-subscribe .form-control:focus {
  box-shadow: none;
}
.footer-subscribe .btn {
  height: 45px;
  border-radius: 15px;
  min-width: 185px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
@media only screen and (min-width: 991px) {
  .footer-form {
    max-width: 440px;
  }
  .footer-border {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
  .footer-space {
    padding-left: 6rem;
  }
}
@media only screen and (max-width: 575px) {
  .footer-subscribe .btn {
    position: static;
    transform: none;
    margin-top: 10px;
  }
  .social-list {
    /* justify-content: center; */
  }
  .social-list a {
    width: 30px;
    height: 30px;
    margin: 10px 12px;
  }
}