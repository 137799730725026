.investment-platform,
.network-hero {
  overflow: hidden;
}
.network-hero-sec-img {
  position: absolute;
  height: 100%;
  left: 59%;
}
.investment-platform {
  background: transparent
    url('https://lilypads.s3.us-east-2.amazonaws.com/images/network/investment-platform-bg.jpg') no-repeat
    center;
  background-size: cover;
}
.investment-platform-img {
  max-height: 400px;
}
.investment-platform-img img {
  filter: drop-shadow(7.24311px 7.24311px 14.4862px rgba(0, 0, 0, 0.1));
}
.our-community-card {
  position: relative;
}
.our-community-card .bordered-text {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-40%);
}
@media only screen and (min-width: 991px) {
  .network-hero-sec {
    position: relative;
    min-height: 75vh;
    display: flex;
    align-items: center;
  }
}
