.LPRetailInvestor-main {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1440px;

  .listitemCon {
    margin-top: 30px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    color: #2f3130;

    .listitem {
      margin-top: 20px;
    }

    svg {
      margin-right: 8px;
    }
  }

  //   background-color: #2f3130;
  .lineConRI {
    display: flex;
    width: 100%;
    // background-color: red;
    align-items: center;
    justify-content: space-between;
    // max-width: 1180px;
    padding: 75px 0;
    margin: auto;
    padding-bottom: 20px;

    h1 {
      width: 520px;

      font-family: "DM Serif Display";
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 130%;
      /* or 62px */

      color: #2f3130;
    }

    h2 {
      width: 520px;

      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      /* or 26px */

      color: #2f3130;
    }
  }

  .tcon {
    h1 {
      font-family: "DM Serif Display";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 140%;
      /* or 50px */

      text-transform: capitalize;

      color: #2f3130;
    }

    h2 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      /* or 29px */

      color: #2f3130;
      max-width: 520px;

      span {
        color: #7c5ac7;
      }
    }
  }

  .leftRightCOn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    position: relative;

    .gone {
      position: absolute;
      pointer-events: none;
      left: -12%;
    }

    .gtwo {
      position: absolute;
      pointer-events: none;
      bottom: 30px;
      right: -11%;
    }

    .lrc-l.cen {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    h2 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      /* or 29px */

      color: #2f3130;
      max-width: 520px;

      span {
        color: #7c5ac7;
      }
    }
  }
}

.LPRetailInvestor-mainCon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topBtmCon {
  display: flex;
  // align-items: center;
  // justify-content: center;
  flex-direction: column;
  position: relative;

  img {
    position: relative;
    z-index: 10;
  }

  .gc {
    position: absolute;
    pointer-events: none;
    overflow: hidden;

    &.lt {
      width: 759px;
      height: 459px;
      top: 0px;
      left: -200px;

      // background-color: rgba(232, 236, 232, 0.082);
      .icrcl {
        position: relative;
        top: -200px;
        width: 459px;
        height: 459px;
        right: -100px;
        border-radius: 50%;
        background: #9e2349;
        opacity: 0.3;
        filter: blur(100px);
      }
    }

    &.rt {
      width: 759px;
      height: 459px;
      top: 0px;
      right: -390px;

      // background-color: rgba(232, 236, 232, 0.082);
      .icrcl {
        position: relative;
        top: -150px;
        width: 335.27px;
        height: 335.27px;
        right: -100px;
        border-radius: 50%;
        background: #9e2349;
        opacity: 0.3;
        filter: blur(100px);
      }
    }

    &.lb {
      width: 759px;
      height: 459px;
      bottom: 0px;
      left: -100px;

      // background-color: rgba(232, 236, 232, 0.082);
      .icrcl {
        position: relative;
        bottom: -250px;
        width: 335.27px;
        height: 335.27px;
        right: -100px;
        border-radius: 50%;
        background: #7d59c3;
        opacity: 0.3;
        filter: blur(100px);
      }
    }

    &.rb {
      width: 759px;
      height: 459px;
      bottom: 0px;
      right: -400px;

      // background-color: rgba(232, 236, 232, 0.082);
      .icrcl {
        position: relative;
        bottom: -250px;
        width: 335.27px;
        height: 335.27px;
        right: -100px;
        border-radius: 50%;
        background: #7d59c3;
        opacity: 0.3;
        filter: blur(100px);
      }
    }
  }

  &.black {
    box-sizing: content-box;
    width: 100%;
    padding: 55px 10%;
    padding-bottom: 0;
    position: relative;
    left: -10%;
    background: #202221;

    .blackBG {
      width: 600vw;
      height: 100%;
      background: #202221;
      position: absolute;
      z-index: -1;
      left: -100vw;
      top: 0;
    }

    h2,
    h1,
    .listitemCon {
      color: white;
    }
  }

  img {
    margin-top: 60px;
  }
}

.topBtmCon-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Lexend";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    color: #2f3130;
    max-width: 520px;

    span {
      color: #7c5ac7;
    }
  }
}


@media only screen and (max-width: 1150px) {
  .LPRetailInvestor-main {
    .lineConRI {
      flex-direction: column;

      h2 {
        margin-top: 20px;
      }

      margin-bottom: -70px;
    }

    .leftRightCOn {
      flex-direction: column;

      img {
        max-width: 100%;
      }

      .lrc-l {
        margin-top: 50px !important;

        &:last-child {
          margin-right: 0 !important;

        }
      }

      &.two {
        flex-direction: column-reverse;
      }
    }

    .topBtmCon-top {
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 600px) {
  .LPRetailInvestor-main {
    .lineConRI {
      padding-top: 0;
      align-items: flex-start;

      h2,
      h1 {
        width: auto;
      }
    }
  }
}