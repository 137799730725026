.lenders-hero {
    position: relative;
}
.lenders-hero-center {
    position: absolute;
    left: 23%;
    bottom: -2%;
    width: 51.8%;
}
.way-to-manage {
    background: #F8F6FC url('https://lilypads.s3.us-east-2.amazonaws.com/images/lenders/way-to-manage-bg.jpg') no-repeat center center ;
    background-size: cover;
}
.vendor-payments {
    background: #F8F6FC url('https://lilypads.s3.us-east-2.amazonaws.com/images/lenders/vendor-payments-bg.jpg') no-repeat top center;
    background-size: cover;
}
.way-to-manage-card {
    position: relative;
    overflow: hidden;
}
.way-to-manage-card-inner {
    position: relative;
    z-index: 1;
}
.way-to-manage-card .bordered-text {
    position: absolute;
    bottom: -10rem;
    right: 0;
}