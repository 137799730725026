/*hero start*/
.hero {
  padding-top: 16.5rem;
  position: relative;
  background-image: url('https://lilypads.s3.us-east-2.amazonaws.com/images/cover/hero-bg.png');
  background-size: cover;
  background-position-x: right;
  overflow: hidden;
  /* min-height: 100vh; */
}
.hero-img-container {
  position: absolute;
  height: 80%;
  width: 50%;
  left: 55%;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  padding-bottom: 6rem;
}

.hero-img-container-inner {
  height: 100%;
}
.hero-main-img {
  height: 100%;
}
.hero-inner-img {
  position: absolute;
  filter: drop-shadow(10px 10px 50px #00000026);
  top: 35%;
  left: -10%;
  z-index: 1;
}
/*hero end*/

/* about us start */
.about-us {
  background-color: #f5f4f8;
}
.about-card {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.about-icon {
  box-shadow: 6px 6px 20px rgba(125, 89, 195, 0.2);
  margin-right: 2rem;
}
.about-title p {
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) {
  .about-us .row {
    --bs-gutter-x: 9rem;
    --bs-gutter-y: 9rem;
  }
  .about-us .about-border {
    position: relative;
  }
  .about-us .about-border:before,
  .about-us .about-border:after {
    content: '';
    position: absolute;
    display: block;
  }
  .about-us .about-border.top-right:before,
  .about-us .about-border.top-center:before,
  .about-us .about-border.top-left:before {
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(47, 49, 48, 0) 0%,
      rgba(47, 49, 48, 1) 100%
    );
    bottom: -4.5rem;
    left: 0;
  }
  .about-us .about-border.top-center:before {
    background: rgba(47, 49, 48, 1);
  }
  .about-us .about-border.top-right:before {
    background: linear-gradient(
      270deg,
      rgba(47, 49, 48, 0) 0%,
      rgba(47, 49, 48, 1) 100%
    );
  }
  .about-us .about-border.bottom-center:after,
  .about-us .about-border.bottom-left:after,
  .about-us .about-border.top-center:after,
  .about-us .about-border.top-left:after {
    width: 2px;
    height: calc(100% + 9rem);
    background: linear-gradient(
      180deg,
      rgba(47, 49, 48, 0) 0%,
      rgba(47, 49, 48, 1) 100%
    );
    top: -4.5rem;
    right: 0;
  }
  .about-us .about-border.bottom-center:after,
  .about-us .about-border.bottom-left:after {
    background: linear-gradient(
      0deg,
      rgba(47, 49, 48, 0) 0%,
      rgba(47, 49, 48, 1) 100%
    );
  }
}
/* about us end */

.manage-dead-flow {
  min-height: 70vh;
  background-color: var(--dark);
  display: flex;
  align-items: flex-end;
  background-image: url('https://lilypads.s3.us-east-2.amazonaws.com/images/cover/manage-dead-flow.jpg');
  background-size: cover;
  background-position: center -60px;
  position: relative;
}
.manage-dead-flow-arrow {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 18%;
}
.manage-dead-flow:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(47, 49, 48, 0) 0%,
    rgba(47, 49, 48, 0.399215) 16.72%,
    rgba(47, 49, 48, 0.755404) 39.04%,
    rgba(47, 49, 48, 0.89084) 55.78%,
    rgba(47, 49, 48, 0.942487) 73.35%,
    #2f3130 100%
  );
}

.home-tabs .nav {
  margin-bottom: 5rem;
}

.home-tabs .nav-item {
  width: 33.33%;
  padding: 0 1.2rem;
}
.home-tabs .nav-link {
  display: flex;
  text-align: left;
  align-items: flex-end;
  cursor: pointer;
}
.home-tabs .nav-link h5 {
  width: 100%;
  font-weight: 800;
  font-family: var(--fontUrbanist);
  color: #929294;
  position: relative;
  padding-bottom: 2.1rem;
  margin-bottom: 0;
  margin-right: 2rem;
}
.home-tabs .nav-link h5:after {
  content: '';
  height: 0.8rem;
  width: 100%;
  background-color: #e2e1e5;
  display: block;
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: 4px;
}
.home-tabs .circle-icon {
  box-shadow: 6px 6px 20px rgba(125, 89, 195, 0.2);
  color: var(--maroon);
}
.home-tabs .nav-link.active h5 {
  color: var(--purple);
}
.home-tabs .nav-link.active h5:after {
  background-color: var(--purple);
}
.home-tabs .nav-link.active .circle-icon {
  background-color: var(--purple);
  color: var(--white);
}

.investor-imgs .investor-img-left {
  position: absolute;
  z-index: 1;
  left: 3%;
  top: 17%;
  transform: rotate(-3deg);
  transition: 0.15s;
  width: 20.5%;
  transform: scale(1.3);
}

.investor-imgs .investor-img-right {
  position: absolute;
  top: 17%;
  right: 3%;
  width: 29.5%;
}
.lenders-imgs .lenders-img-left {
  position: absolute;
  z-index: 1;
  left: 3%;
  top: 17%;
  transform: scale(1.3);

  transition: 0.15s;
  width: 21%;
}

.investor-imgs .investor-img-left:hover {
  transform: rotate(-3deg) scale(1.4);
}

.lenders-imgs .lenders-img-left:hover {
  transform: rotate(-3deg) scale(1.4);
}

.lenders-imgs .lenders-img-right {
  position: absolute;
  top: 17%;
  right: 3%;
  transform: rotate(2deg) scale(1.1);
  width: 27.68%;
}
.lenders-imgs .lenders-img-right:hover {
  transform: rotate(2deg) scale(1.2);
}
.brokers-imgs .brokers-img-left:hover {
  transform: rotate(-2deg) scale(1.4);
}
.brokers-imgs .brokers-img-left {
  position: absolute;
  z-index: 1;
  width: 22.5%;
  left: 3%;
  top: 12%;
  transform: scale(1.3);
}
.brokers-imgs .brokers-img-right {
  position: absolute;
  z-index: 1;
  left: 24%;
  top: 5%;
  width: 61.6%;
}
.join-lily-home {
  background: #f5f4f8
    url('https://lilypads.s3.us-east-2.amazonaws.com/images/home/join-lily.png')
    no-repeat center bottom;
  /* background-size: cover; */
}
.product-images {
  position: relative;
  overflow: hidden;
  background: #fff
    url('https://lilypads.s3.us-east-2.amazonaws.com/images/home/product-images-bg.png')
    no-repeat top center;
}
.product-images-img {
  position: absolute;
  left: 53%;
  top: 50%;
  transform: translateY(-50%) rotate(3deg);
  max-height: 80%;
}
@media only screen and (max-width: 1400px) {
  .hero {
    background-size: auto;
    background-position: left;
  }
}

@media only screen and (max-width: 991px) {
  .hero {
    background-size: cover;
  }
  .hero-img-container {
    position: static;
    width: 500px;
    margin: 0 auto;
    padding-bottom: 0;
    margin-top: 30px;
  }
  .hero-img-container-inner {
    /* height: auto; */
  }
  .product-images-img {
    margin-top: 4rem !important;
  }
}
@media only screen and (max-width: 767px) {
  .hero-img-container {
    width: 100%;
    max-width: 600px;
    padding: 0 30px;
    margin-top: 30px;
    height: 400px;
    justify-content: center;
  }
  .home-tabs .nav {
    margin-top: 5rem;
  }
  .home-tabs .nav-link h5 {
    margin-right: 0;
  }
  .home-tabs .nav-link {
    padding: 0;
  }
  .home-tabs .nav-item {
    padding: 0 2px;
  }
  .home-tabs .circle-icon {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-border {
    position: relative;
    padding-bottom: 10px;
  }
  .about-border:not(.top-left):after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(47, 49, 48, 0) 0%,
      rgba(47, 49, 48, 1) 50%,
      rgba(47, 49, 48, 0) 100%
    );
    bottom: -5px;
    left: 0;
  }
  .manage-dead-flow-arrow {
    left: 50%;
    transform: translateX(-50%);
  }
  .d-arrow {
    width: 50px;
  }
  .product-images-img.img-reset-mob {
    transform: rotate(3deg);
  }
  .manage-dead-flow {
    min-height: 600px;
  }
}

@media screen and (min-width: 2024px) {
  .hero-txt-container {
    /* position: absolute;
    height: 80%;
    width: 50%;
    left: 25%;
    top: 40%;
    transform: translateY(-50%);
    display: flex;
    align-items: flex-end;
    z-index: 1;
    padding-bottom: 6rem; */
  }
}

@media only screen and (max-width: 500px) {
  .hero-img-container {
    height: 300px;
  }
}

.headerHERO {
  font-size: 6.1rem;
}
.lexlendFONT {
  font-family: 'Lexend' !important;
}
