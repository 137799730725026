.smallBox {
  width: 180px;
  height: 180px;
  left: 785px;
  top: 146px;
  background: rgba(124, 90, 199, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
  &.pink {
    background: rgba(158, 35, 73, 0.1);
  }
  &.beige {
    background: #fef2de;
  }
  &.beige {
    background: #e3f3e0;
  }
}
.LargeBox {
  width: 380px;
  height: 180px;
  left: 985px;
  top: 146px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(47, 49, 48, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
}

.spacer40mt {
  margin-top: 40px;
}
.spacer60mt {
  margin-top: 60px;
}
.lpnLine {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.LPNSecOne {
  max-width: 1440px;
  padding: 0 76px;
  width: 100%;
  //   background-color: red;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .LPNSecOne-l {
    .tcon {
      h1 {
        max-width: 550px;
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 53px;
        line-height: 130%;
        color: #2f3130;
        span {
          color: #9e2349;
          margin-left: 12px;
        }
      }

      h2 {
        max-width: 550px;
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 140%;
        /* or 50px */

        letter-spacing: 0.01em;

        /* Text color */

        color: #2f3130;
        span {
          color: #9e2349;
          margin-left: 12px;
        }
      }
      h3 {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.004em;
        color: #2f3130;
        margin-bottom: 20px;
      }
      p {
        max-width: 410px;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;
        /* or 32px */

        letter-spacing: 0.004em;

        /* Text color */

        color: #2f3130;
      }
      .mainBtn {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        /* or 22px */

        text-align: center;
        letter-spacing: 0.004em;

        /* white */

        color: #ffffff;
        padding: 19px 35.5px;
        background: #7c5ac7;
        /* cta-downshadow */
        width: fit-content;

        box-shadow: 0px 100px 40px rgba(125, 89, 195, 0.03),
          0px 56px 34px rgba(125, 89, 195, 0.1),
          0px 25px 25px rgba(125, 89, 195, 0.17),
          0px 6px 14px rgba(125, 89, 195, 0.2),
          0px 0px 0px rgba(125, 89, 195, 0.2);
        border-radius: 16px;
      }
      svg {
        margin-left: 8px;
      }

      a {
        position: relative;
        top: 28px;
        cursor: pointer;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.004em;
        color: #7c5ac7;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: scale(1.04);
        }
      }
    }
  }
}

.LPNSecSix {
  padding: 0 76px;
  width: 100%;
  background-color: #2f3130;
  // height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-family: 'DM Serif Display';
    font-style: italic;
    font-weight: 400;
    font-size: 38px;
    line-height: 130%;
    /* or 49px */

    letter-spacing: 0.004em;

    /* white */

    color: #ffffff;
    position: relative;
    // top: -90px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.gradLine {
  content: '';
  height: 0px;
  width: 1px;
  transition: all;
  transition-duration: 600ms;
  transition-timing-function: ease;
  background: linear-gradient(transparent, white, transparent);
}

.gradLineH {
  content: '';
  width: 0px;
  height: 2px;
  transition: all;
  transition-duration: 600ms;
  transition-timing-function: ease;
  background: linear-gradient(transparent, white, transparent);
}

.compContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 190px;
  height: 130px;
  padding: 24px 0;
  box-sizing: border-box;
  // background-color: rgb(4, 0, 255);
  img {
    max-height: 120px;
  }
  &.fh {
    pointer-events: none;
    opacity: 0;
  }
  &:hover {
    .gradLine {
      height: 270px;
    }
    .gradLineH {
      width: 270px;
    }
  }
  .gradLineH {
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    &.top {
      top: 4px;
    }
    &.btm {
      bottom: 4px;
    }
  }
  .gradLine {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}

.LPNSecSix-Line {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 100px;
}

.LPNSecFive {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #7c5ac7 53.69%, #623db3 100%);
}

.countUpCompCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 75px;
  max-width: 1440px;
  // background: #fff;
  width: 100%;
}
.countUpComp {
  h1 {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 96px;
    /* identical to box height */

    letter-spacing: 0.004em;

    /* white */

    color: #ffffff;

    span {
      font-family: 'Urbanist';
      font-style: normal;
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
      letter-spacing: 0.004em;

      /* white */

      color: #ffffff;
    }
  }
  .countUpC {
    svg {
      margin-right: 10px;
    }
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: 0.004em;

    /* white */

    color: #ffffff;
  }
}

:root {
  --backgroundColor: #292929;
  --primaryColor: #0aa1ff;
  --primaryTextColor: #e6e6e6;

  --headlineBig: clamp(5rem, 9.9vw, 15rem);
  --headlineMedium: clamp(1rem, 2vw, 4rem);
  --headlineSmall: clamp(0.6rem, 0.75vw, 1.3rem);
  --spacing: clamp(0.9rem, 0.75vw, 1.3rem);
}

//scrool Trigger
.scroll-section-outer {
  overflow: hidden;
  position: relative;
}
.scroolHOLDER {
  height: 100%;
  position: relative;
  .newITEM {
    position: absolute;
    width: 100vw;
    height: 100vh;
    // background: rgba(255, 0, 0, 0.267);
    z-index: 100;
    p {
      position: absolute;
      top: 11vh;
      left: 76px;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */

      letter-spacing: 0.004em;

      /* white */

      color: #ffffff;
    }
    h2 {
      position: absolute;
      bottom: 10vh;
      left: 76px;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      /* or 22px */

      letter-spacing: 0.004em;
      text-transform: uppercase;

      /* white */

      color: #ffffff;

      opacity: 0.5;
    }
  }
}
.scroll-section-inner {
  height: 100vh;
  width: 200vw;
  display: flex;
  flex-direction: row;
  position: relative;
  // background: red;
}

.scroll-section {
  height: 100vh;
  width: 200vw;
  box-sizing: border-box;
  padding: 0 76px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #2f3130;
  img {
    // width: 170vw;
  }
}

.scroll-section h3 {
  color: var(--primaryTextColor);
  letter-spacing: var(--headlineMedium);
  font-size: var(--headlineMedium);
  text-transform: uppercase;
}

.bgBlack {
  background-color: #2f3130;
}

.lpnScroolCon {
  justify-content: space-between;
  max-width: 1440px;
  width: 90vw;
}
.LPNetworkCommunity-NEW-L {
  max-width: 40%;
  display: flex;
  flex-direction: column;

  .dynamicText {
    margin-top: -200px;
    display: flex;
    flex-direction: column;
    // background-color: red;
    justify-content: center;
    max-width: 480px;
    .dtH {
      font-family: 'DM Serif Display';
      font-style: normal;
      font-weight: 400;
      font-size: 38px;
      line-height: 130%;
      /* or 49px */
      letter-spacing: 0.004em;
      /* Text color */
      margin-bottom: 12px;
      color: #2f3130;
    }

    .dtP {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 180%;
      /* or 32px */
      letter-spacing: 0.004em;
      /* Text color */
      color: #2f3130;
    }
  }
  .LPNetworkCommunity-NEW-L-Item {
    display: flex;
  }
  .lpNewVLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      /* identical to box height, or 20px */

      letter-spacing: 0.004em;
      text-transform: uppercase;

      /* Primary 2 */

      color: #9e2349;

      opacity: 0.5;
      transform: rotate(-90deg);
      margin: 30px 0;
    }
  }
  .headNav {
    margin-top: -10px;
    margin-bottom: 30px;
    margin-left: 27px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: 0.004em;

    /* Text color */

    color: #2f3130;
  }
}
.LPNSecSix,
.LPContactUsNEW {
  // display: none;

  z-index: 900;
  position: relative;
  // background-color: white;
}
.LPContactUsNEW {
  // background-color: white !important;
}

.LPFullPageScroll {
  width: 100%;
  // height: 100vh;
  // background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   // display: none;
  // }
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    // font-size: 200px;
    background: white;
    // border: 1px solid red;
    // scroll-snap-align: start;
    // padding: 0 76px;
    // box-sizing: border-box;
    // max-width: 1440px;
    .pannelmcCon {
      // background-color: red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1440px;
      width: 100%;
    }
    &.one {
      background: #ffebf1;
    }
    &.two {
      background: #f1ebff;
    }
    &.three {
      background: #ffebf1;
    }
    h2 {
      font-family: 'DM Serif Display';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 130%;
      /* identical to box height, or 42px */

      letter-spacing: 0.004em;

      /* Text color */

      color: #2f3130;
    }
    h3 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      /* or 29px */

      letter-spacing: 0.004em;

      /* Text color */

      color: #2f3130;
    }
    .panel-R {
      max-width: 600px;
    }
    .panel-L {
      position: relative;
      top: -60px;
      // background-color: red;
      img {
        margin-top: 90px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
      li {
        svg {
          margin-right: 10px;
        }
        margin-top: 10px;
        list-style: none;
      }
    }
  }
}
.fwP {
  width: 100%;
  height: 100vh;
  // background-color: red;
  z-index: 100;
  position: relative;
  section {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;
    // font-size: 200px;

    background: white;
    // border: 1px solid red;
    // scroll-snap-align: start;
    // padding: 0 76px;
    // box-sizing: border-box;
    // max-width: 1440px;
    .pannelmcCon {
      // background-color: red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1440px;
      width: 100%;
    }
    &.one {
      background: #ffebf1;
    }
    &.two {
      background: #f1ebff;
    }
    &.three {
      background: #ffebf1;
    }
    h2 {
      font-family: 'DM Serif Display';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 130%;
      /* identical to box height, or 42px */

      letter-spacing: 0.004em;

      /* Text color */

      color: #2f3130;
    }
    h3 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      /* or 29px */

      letter-spacing: 0.004em;

      /* Text color */

      color: #2f3130;
    }
    .panel-R {
      max-width: 600px;
    }
    .panel-L {
      position: relative;
      top: -60px;
      // background-color: red;
      img {
        margin-top: 90px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 30px;
      li {
        display: flex;
        svg {
          margin-right: 10px;
        }
        margin-top: 10px;
        list-style: none;
      }
    }
  }
}

.mt10Self {
  svg {
    margin-left: 10px;
  }
  &:hover {
    svg {
      path {
        stroke: #884bae;
      }
    }
  }
}

.LPNetworkCommunity-main {
  position: relative;
}
.ProgressLine {
  position: absolute;
  // position: fixed;
  left: 0;
  bottom: -20px;
  width: 0%;
  height: 40px;
  z-index: 900;
  transition: all 0.5s ease;
  background-color: #7c5ac7;
  // background-color: red;
  // &.active{
  //   position: fixed;
  // }
}

.FPSCON {
  display: flex;

  .FPS-ITEM {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 500px;
    img {
      width: 140px;
      margin-bottom: 69px;
    }
    .fpsLine {
      width: 100%;
      height: 6px;
      background-color: white;
      position: relative;
      margin-bottom: 40px;
      .fpsLineCircle {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background: #7c5ac7;
        /* white */
        border-radius: 50%;
        border: 5px solid #ffffff;
      }
    }
    h2 {
      font-family: 'DM Serif Display';
      font-style: italic;
      font-weight: 400;
      font-size: 36px;
      line-height: 140%;
      /* or 50px */

      letter-spacing: 0.004em;

      /* white */
      max-width: 400px;
      color: #ffffff;
      margin-bottom: 12px;
    }
    p {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      /* or 29px */

      letter-spacing: 0.004em;

      /* white */
      max-width: 400px;
      color: #ffffff;
    }
  }
}
.LPContactUsNEW-MAIN {
  display: flex;
  background: #393c3b;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 900;
}
.LPContactUsNEW {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 76px;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  background: #393c3b;
  max-width: 1440px;
  .LPContactUsNEW-Form {
    display: flex;
    // background-color: red;
    width: 500px;
  }
  .formp {
    color: #ffffff;

    opacity: 0.5;
  }
  .fhead {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 130%;
    /* identical to box height, or 55px */

    letter-spacing: 0.004em;

    /* white */

    color: #ffffff;
    span {
      color: #9e2349;
    }
  }
  .form-control {
    background-color: #393c3b;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
  }
  .contact-devider:before {
    background-color: white;
  }
  .contact-devider span {
    background: #393c3b;
    color: white;
  }
  .ccNew {
    span {
      background-color: #393c3b !important;
      border: 1px solid #6fba61;
      color: #6fba61;
    }
  }

  .ccNew input:checked + span {
    // display: none;
    background-color: #6fba61 !important;
  }

  .LPContactUsNEW-animateCon {
    height: 100%;
    gap: 40px;
    display: flex;
    overflow: hidden;
    img {
      height: fit-content;
      width: 230px;

      animation-duration: 30000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      position: relative;
      top: -505px;
      &.one {
        top: -1505px;
        // top: 600px;
        animation-name: spin;
        // animation-direction: reverse;
      }
      &.two {
        top: 505px;
        // top: -1205px;
        animation-name: spintwo;
      }
    }

    @keyframes spintwo {
      0% {
        top: 505px;
      }
      // 25% {
      //   top: 0px;
      // }
      50% {
        top: -1405px;
      }
      // 75% {
      //   top: 0px;
      // }
      100% {
        top: 505px;
      }
    }

    @keyframes spin {
      0% {
        top: -1505px;
      }
      // 25% {
      //   top: 0px;
      // }
      50% {
        top: 600px;
      }
      // 75% {
      //   top: 0px;
      // }
      100% {
        top: -1505px;
      }
    }
  }
}
.LPNSecOne-MAIN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lpspacer {
  padding: 30px 0;
}
.lpspacerIMG {
  margin-top: 20px;
}
@media only screen and (max-width: 1200px) {
  .LPNSecSix h1 {
    top: auto;
    margin-bottom: 40px;
  }
  .LPNSecFive {
    height: auto;
    padding: 20px 0;
  }
  .countUpCompCon {
    flex-wrap: wrap;
    gap: 20px;
  }
  .LPNSecOne {
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
  .LPNSecOne .LPNSecOne-l .tcon h3,
  .LPNetworkCommunity-NEW-L .headNav {
    font-size: 16px;
  }

  .LPNSecOne .LPNSecOne-l .tcon h2,
  .LPNetworkCommunity-NEW-L .dynamicText .dtH {
    font-size: 26px;
  }

  .LPNSecOne .LPNSecOne-l .tcon h2 span {
    margin-left: 0;
  }
  .lpnScroolCon {
    flex-direction: column-reverse;
  }
  .LPNetworkCommunity-Container .LPNetworkCommunity-R,
  .lpNewVLine {
    transform: scale(0.6);
    margin: -140px 0;
  }
  .LPNetworkCommunity-NEW-L {
    width: 100%;
    max-width: 100%;
  }
  .LPNetworkCommunity-NEW-L .dynamicText {
    position: relative;
    top: 80px;
  }
  .LPNetworkCommunity-Container .LPNetworkCommunity-R {
    width: auto;
  }
  .LPNSecSix-Line {
    grid-template-columns: auto auto;
    gap: 100px;
  }
}

@media only screen and (max-width: 1100px) {
  .fwP section .panel-R {
    max-width: auto;
    .tcon {
      width: 90%;
    }
  }
  .panel-L {
    img {
      max-width: 80%;
    }
  }
  .pannelmcCon {
    flex-direction: column;
  }
  .LPContactUsNEW-MAIN {
    height: auto;
    padding: 20px;
  }
  .LPContactUsNEW {
    height: auto;
    padding: 0;
    .LPContactUsNEW-animateCon {
      display: none;
    }
  }
}
@media only screen and (max-width: 770px) {
  .fwP {
    height: auto;
  }
  .fwP section {
    height: auto;
    padding: 20px;
  }
  .fwP section .panel-L {
    top: 0;
  }
  .fwP section .panel-L img {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .FPSCON {
    position: relative;
    left: -50px;
  }
  .scroolHOLDER .newITEM p,
  .scroolHOLDER .newITEM h2 {
    left: 25px;
  }
  .LPNSecSix {
    padding: 20px 0;
  }
  .countUpCompCon {
    padding: 20px;
  }
  .LPNSecSix-Line {
    grid-template-columns: auto;
    gap: 60px;
  }
  .countUpCompCon {
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .countUpComp h1 {
    font-size: 60px;
  }
  .countUpComp .countUpC {
    svg {
      transform: scale(0.8);
      // margin-right: -6px;
    }
    font-size: 16px;
  }
  .LPNSecOne .LPNSecOne-l .tcon h3 {
    font-size: 16px;
  }

  .LPNSecOne .LPNSecOne-l .tcon h2,
  .LPNetworkCommunity-NEW-L .dynamicText .dtH {
    font-size: 18px;
  }
}
