.institutional-investors-hero-cols {
  position: relative;
}
.institutional-investors-hero-img {
  position: absolute;
  width: auto;
  position: absolute;
  margin: 0;
  top: 30px;
  left: 42%;
  transform: translateX(-50%);
  max-width: 80rem;
}
.institutional-investors-discover {
  position: relative;
  overflow: hidden;
}
.institutional-investors-discover-img {
  position: absolute;
  left: 52%;
  top: 50%;
  transform: translateY(-50%);
  height: 85%;
}
.who-we-are-wrapper {
  background: transparent
    url("https://lilypads.s3.us-east-2.amazonaws.com/images/institutional-investor/who-we-are-bg.png")
    no-repeat top center;
  background-size: cover;
}
.who-we-are-block {
  display: flex;
  align-items: center;
  position: relative;
}
.who-we-are-block .circle-icon {
  box-shadow: 0px 0px 50px rgba(166, 36, 75, 0.1);
  position: relative;
  z-index: 1;
}
.i-investor-hero {
  position: relative;
}
.i-investor-hero .bordered-text {
  position: absolute;
  font-size: 10rem;
  top: -40%;
  left: 0;
  color: var(--white);
}
.i-investor-hero h2 {
  position: relative;
  z-index: 1;
}
.who-we-are-block:not(:last-child):before {
  position: absolute;
  content: "";
  height: 100%;
  width: 2px;
  background-color: var(--purple);
  left: 4rem;
  top: 4rem;
}
.deal-making .circle-icon {
  background-color: rgba(124, 89, 195, 0.1);
}
.fdt .bg-dark-gray {
  border-radius: 20px;
}
.fdt-left {
  position: relative;
}
.fdt-left .crown-img {
  position: absolute;
  transform: rotate(35deg);
  top: -20px;
  right: -20px;
}
.fdt-accordion .accordion-item:not(:last-child) {
  border: 0;
  margin-bottom: 20px;
}
.fdt-accordion .accordion-button {
  background-color: transparent;
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  font-family: var(--fontLexend);
  box-shadow: none;
  border: 0;
  padding: 0;
}
.fdt-accordion .accordion-body {
  padding: 0;
}
.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='9' width='2' height='20' fill='%2347E6B1'/%3E%3Crect x='20' y='9' width='2' height='20' transform='rotate(90 20 9)' fill='%2347E6B1'/%3E%3C/svg%3E");
  background-size: 20px;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(45deg);
}
.fdt ul li p,
.fdt ul {
  color: var(--white);
  margin-bottom: 0;
}
.fdt ul li:not(:last-child) {
  margin-bottom: 10px;
}
.manage-account {
  position: relative;
  overflow: hidden;
}
.manage-account-img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 48%;
}
@media only screen and (max-width: 767px) {
  .i-investor-hero {
    margin-bottom: 4rem;
  }
  .who-we-are-block {
    align-items: flex-start;
  }
  .fdt-left .crown-img {
    right: 0;
    transform: translateY(-80%);
    top: 0;
  }
}
