.broker-hero-nav {
  padding: 0 2rem 2rem;
  position: relative;
  cursor: pointer;
}
.broker-hero-nav:before,
.broker-hero-nav:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: rgba(47, 49, 48, 0.1);
  display: block;
  border-radius: 3px;
}
.broker-hero-nav:before {
  background-color: var(--purple);
  width: 100%;
  opacity: 0;
}
.broker-hero-nav.active:before {
  opacity: 1;
}
.broker-hero-img {
  max-height: 56rem;
  overflow: hidden;
}
.streamline-img {
  max-height: 53rem;
  overflow: hidden;
}
.broker-streamline {
  background: #f8f6fc url("https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/streamline-bg.jpg")
    no-repeat center center;
  /* background-size: cover; */
}
.customer-exp-img {
  position: relative;
}
.customer-exp-img-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 3rem;
}
.compliance-mgnt {
  background: rgba(158, 35, 74, 0.1)
    url("https://lilypads.s3.us-east-2.amazonaws.com/images/brokers/compliance-mgnt-bg.jpg") no-repeat top left;
  position: relative;
  overflow: hidden;
}
.compliance-mgnt-img {
    position: absolute;
    top: 6rem;
    left: 45%;
}
@media only screen and (min-width: 991px) {
  .compliance-mgnt .row {
    padding-bottom: 8rem;
  }
}
@media only screen and (max-width: 767px) {
  .customer-exp-img {
    margin-bottom: 5rem;
  }
  .customer-exp-img-content {
    bottom: -5rem;
    padding: 2rem 2rem 0;
  }
}