.LPNetworkCommunity-Container {
    // background-color: red;
    // width: 1040px;
    display: flex;

    position: relative;

    .intersectionCheck {
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 255, 0);
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }

    .intersectionCheckT {
        width: 30px;
        height: 30px;
        background-color: rgba(179, 255, 0, 0);
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
    }

    .LPNetworkCommunity-L {
        max-width: 518px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2 {
            font-style: normal;
            text-transform: capitalize;
        }

        button {
            width: 250px;
        }

        .pM {
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            /* or 150% */

            text-transform: capitalize;

            color: #2F3130;


        }
    }

    .LPNetworkCommunity-R {
        width: 750px;
        height: 649px;
        // background-color: rgba(58, 55, 55, 0);
        position: relative;

        .networkSliderMain-Item {
            transition: all 0.5s ease;
            left: auto;
            top: 0%;
            right: auto;
            bottom: auto;

            &.posiOUT {
                position: absolute;
                left: 190%;
                transform: translateX(-50%);
                z-index: 8;
            }

            &.posiOne {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 8;
            }

            &.posiTwo {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) scale(0.8);
                opacity: 0.3;
                z-index: 5;


            }
            &.posisemiOUT {
                position: absolute;
                right: -110px;
              
                top: 50%;
                transform: translateY(-50%) scale(0.8);
                opacity: 0;
                z-index: 5;


            }

            &.posiThree {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%) scale(0.8);
                opacity: 0.3;
                z-index: 4;
                top: auto;
            }

            &.posiFour {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) scale(0.8);
                opacity: 0.3;
                z-index: 5;
            }
        }


    }

}

.LPNetworkCommunity-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-top: 50px;

}