.contact-us-hero {
  background: transparent url("https://lilypads.s3.us-east-2.amazonaws.com/images/calendly/bg.png") no-repeat;
  background-position: center 13rem;
}
/* FOLOW US SLIDER */
.follow-us-section {
  background-color: rgba(241, 238, 249, 1);
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.follow-card-wrap {
  position: relative;
  padding-bottom: 100%;
}

.follow-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.follow-card img {
  width: 100%;
  height: 100%;
}

.follow-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
}

.follow-icon:hover {
  color: black;
}

.follow-us-section .slick-slide {
  padding: 0 15px;
}

/* .follow-us-section .slick-slide > div {
  margin-right: 30px;
} */
/* FOLOW US SLIDER END */
