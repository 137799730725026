.our-principle-card {
  position: relative;
}
.our-principle-card .bordered-text {
  position: absolute;
  font-size: 9.5rem;
  top: 0;
  right: 10px;
  color: var(--white);
  /* -webkit-text-stroke-color: rgba(182, 40, 85, 0.8); */
}
.why-invest-card {
  position: relative;
}
.why-invest-card .bordered-text {
  font-size: 9.6rem;
  position: absolute;
  color: var(--bgDark);
  /* -webkit-text-stroke-color: var(--maroon); */
  top: 0;
  left: 0;
}
.why-invest-card p {
  position: relative;
  z-index: 1;
}
.member-name {
  border-bottom: 1px solid var(--maroon);
  padding-right: 4rem;
}
.member-sign h6 {
  transform: rotate(-15deg);
}
.memeber-socials {
  text-align: center;
  margin: 25px 0 10px;
}
.memeber-socials a {
  color: var(--purple);
  margin: 0 10px;
}