.LPExploreOfferingMain {

    width: 90%;
    margin: 0 5%;
    margin-top: 120px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // background-color: blue;
    .searchBox {
        width: 100%;

        background: #FFFFFF;
        border: 1px solid rgba(44, 42, 49, 0.2);
        border-radius: 10px;
        position: relative;
        // height: 40px;
        height: 60px;
        margin-bottom: 30px;

        input {
            width: 100%;
            padding: 10px;
            border: none;
            outline: none;
            height: 100%;
            // display: none;
            background-color: rgba(255, 255, 255, 0);
        }

        .svgCon {
            background: #FFFFFF;
            box-shadow: 6px 6px 16px rgba(124, 90, 199, 0.3);
            border-radius: 12px;
            position: absolute;
            right: 10px;
            top: 50%;
            padding: 5px;
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
        }
    }



    .LPOfferringCardHolder {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // background-color: green;
        align-items: center;
        justify-content: space-between;
        row-gap: 30px;
        margin-bottom: 50px;
    }
}

.LPOfferringCard {
    display: flex;
    flex-direction: column;
    // width: fit-content;
    padding: 12px 18px;
    // background-color: red;

    border-radius: 26.8397px;
    width: 400px;
    border: 0.5px solid #e6e6e6;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;

    &:hover {
        transform: scale(1.01);
        border: 0.5px solid #e6e6e600;
        box-shadow: -13.4198px 13.4198px 44.7328px rgba(124, 90, 199, 0.15);
    }

    .dangerIcon {
        position: absolute;
        top: -10px;
        right: -10px;

        &:hover {
            .topHover {

                // display: flex;
            }
        }

        .topHover {
            position: relative;
            display: none;
            p{
                top: -30px;
                position: absolute;
                background-color: rgb(104, 103, 103);
                // display: none;
                color: white;
                right: 50%;
                transform: translateX(50%);
                padding: 4px 8px;
                z-index: 300;
                // display: flex;
                width: 250px;
                border-radius: 4px;
                // width: fit-content;
                text-align: center;
            }
          
        }

        img {

            width: 30px;
            // height: 10px;
        }
    }

    .exploreICon {
        position: relative;
        width: 100%;

        img {
            width: 100%;
        }

        .hoverCard {
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 130%;
            /* identical to box height, or 13px */

            text-transform: uppercase;

            color: #FFFFFF;
            position: absolute;
            top: 10px;
            left: 6px;
            padding: 6px 12px;
            background: #7C5AC7;
            border-radius: 5px;
        }

    }

    .headText {
        margin-top: 16px;
        margin-bottom: 18px;

        h1 {
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 600;
            font-size: 21.4717px;
            line-height: 21px;
            /* identical to box height, or 100% */


            color: #2F3130;
        }

        .locationCon {

            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            svg {
                margin-top: 2px;
            }

            p {
                margin: 0;
                padding: 0;
                font-family: 'Lexend';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                /* identical to box height */


                color: #2F3130;

                opacity: 0.6;
            }
        }
    }

    .lineExp {
        display: flex;

        column-gap: 10px;
        margin-bottom: 10px;

        &.two {
            .expBox {
                background: #F4F6F9;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 4px;
            }
        }

        &.three {
            .expBox {

                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 4px;
            }
        }

        .expBox {
            background: #EBE5F7;
            border-radius: 4px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding: 6px 0;

            h3 {
                margin: 0;
                padding: 0;

                font-family: 'Lexend';
                font-style: normal;
                font-weight: 300;
                font-size: 10px;
                line-height: 14px;
                /* or 215% */

                text-align: center;
                color: #000000;
                opacity: 0.6;
            }

            h4 {
                margin: 0;
                padding: 0;
                margin: 5px 0;
                font-family: 'Lexend';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                /* identical to box height, or 153% */


                color: #000000;

            }
        }
    }



    .expButton {
        background: #7C5AC7;
        box-shadow: 0px 5.36794px 13.4198px rgba(0, 0, 0, 0.15);
        border-radius: 13.4198px;
        width: 100%;
        padding: 10px 0;
        cursor: pointer;
        transition: all 0.5s ease;
display: flex;
align-items: center;
justify-content: center;
        &:hover {
            transform: scale(1.03);
        }

        a {
            margin: 0;
            padding: 0;
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 600;
            font-size: 12.5252px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.05em;

            color: #FFFFFF;
        }

    }
}