header {
  position: relative;
  z-index: 99;
  max-width: 1720px;
  margin: 0 auto;
  padding: 0 5rem;
}
header .navbar {
  padding: 2rem 0;
}
header .navbar-light .navbar-nav .nav-link {
  font-family: var(--fontLexend);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--dark);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
header .navbar-light .navbar-nav .nav-link.active {
  color: var(--purple);
  position: relative;
}
header .navbar-light .navbar-nav .nav-link.active:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width: 6px;
  height: 6px;
  background-color: var(--purple);
  border-radius: 50%;
}
header .navbar-light .navbar-nav .nav-link:hover {
  color: var(--purple);
}
header .navbar-brand {
  margin-right: 6rem;
  padding: 0;
  position: relative;
  z-index: 9;
}

header .dropdown-item {
  display: flex;
  align-items: center;
  padding: 0 5px !important;
  justify-content: space-between;
}
header .dropdown-item.nav-link {
  font-weight: 600 !important;
  background-color: transparent;
  text-transform: capitalize !important;
  font-size: 16px !important;
}
header .dropdown-item.nav-link.active,
header .dropdown-item.nav-link:hover {
  color: var(--maroon) !important;
}
header .dropdown-item.nav-link:after {
  content: none !important;
}
header .dropdown-item.nav-link img {
  margin-right: 8px;
}
header .dropdown-item.nav-link svg {
  color: var(--purple);
}
header .dropdown-item:not(:last-child) {
  margin-bottom: 20px;
}
header .dropdown-menu {
  width: 300px;
  padding: 2rem;
  border-radius: 3rem;
  box-shadow: 0px 10px 30px rgba(49, 39, 120, 0.1);
  border: 0;
  margin-top: 0 !important;
}
header .dropdown-toggle::after {
  content: none;
}

header.header-black .navbar-nav .nav-link {
  color: var(--white);
}

@media only screen and (max-width: 1299px) {
  header .dropdown-item.nav-link {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1199px) {
  header .btn {
    padding: 8px 1.6rem;
  }

  header .navbar-light .navbar-nav .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  header.header-black {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-light .navbar-nav .dropdown-toggle.nav-link:focus + div {
    display: block;
    position: absolute;
  }
  .navbar-toggler {
    width: 35px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    border-radius: 5px;
    border: none;
    text-align: center;
    position: absolute;
    right: 15px;
    z-index: 110;
    transition: all 0.5s;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    vertical-align: middle;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler span {
    position: relative;
    width: 25px;
    height: 2px;
    background-color: var(--maroon);
    display: block;
    background-image: none !important;
    margin-left: auto;
  }

  .navbar-toggler span::after,
  .navbar-toggler span::before {
    position: absolute;
    content: "";
    width: 17px;
    height: 2px;
    background-color: var(--maroon);
    right: 0;
    transition: all 0.5s;
  }

  .navbar-toggler span::before {
    top: -7px;
  }

  .navbar-toggler span::after {
    top: 7px;
  }

  .show-menu .navbar-toggler span::after,
  .show-menu .navbar-toggler span::before {
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
    top: -1px;
  }

  .show-menu .navbar-toggler span::after {
    transform: rotate(-45deg);
  }

  .show-menu .navbar-toggler span {
    height: 0;
  }

  .show-menu {
    overflow-y: hidden;
  }
  .navbar .container {
    position: relative;
  }

  .navbar-left {
    margin-right: 45px;
  }

  .navbar-collapse {
    background-color: #f9faff;
    padding: 90px 15px 15px;
    position: fixed;
    top: 0;
    right: 0;
    display: block !important;
    width: 60% !important;
    text-align: left;
    bottom: 0;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    -webkit-transform: translateX(110%);
    -moz-transform: translateX(110%);
    -ms-transform: translateX(110%);
    -o-transform: translateX(110%);
    transform: translateX(110%);
    height: 100%;
    overflow: auto;
    border-radius: 0;
    box-shadow: -10px 10px 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .show-menu .navbar-collapse {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  header .navbar-light .navbar-nav .nav-link {
    margin-bottom: 10px;
  }
  header .navbar-light .navbar-nav .nav-link.active:after {
    content: none;
  }
  header .dropdown-menu {
    width: 100%;
  }

  header.header-black .navbar-nav .nav-link {
    color: var(--dark);
  }
}

@media only screen and (max-width: 767px) {
  header {
    padding: 0 15px;
  }
  header .navbar-brand img {
    max-width: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .navbar-collapse {
    width: 100% !important;
  }
}
