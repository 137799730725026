.mteamSlider {

    height: 600px;
    width: 100%;
    // background-color: rgb(255, 0, 0);
    // background-color: rgb(165, 162, 162);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: flex-end;

    .mTS-L {

        position: absolute;

        top: 37px;
        left: 0px;
        z-index: 15;

        .tcon {
            display: flex;
            flex-direction: column;
            max-width: 500px;

            h1 {
                font-family: 'DM Serif Display';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                /* identical to box height, or 150% */
                color: #9E2349;
            }

            h2 {
                font-family: 'Lexend';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                letter-spacing: 0.15px;

                color: #202221;
                border-bottom: 1px solid #9E2349;
                padding-bottom: 15px;
                padding-right: 40px;
            }

            h3 {
                margin-top: 12px;
                font-family: 'Lexend';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                /* or 150% */

                letter-spacing: 0.15px;

                color: #202221;
                max-width: 410px;
            }
        }

        .mTs-L-Top {
            display: flex;
            // background-color: blue;
            max-width: 450px;
            align-items: center;
            justify-content: space-between;
        }

        .mTs-L-BTM {

            svg {
                margin-top: 7px;
                margin-right: 10px;
            }
        }
    }

    .mTS-R {
        position: relative;
        width: 100%;

        .tconD {
            position: absolute;
            bottom: 255px;
            left: -220px;
            display: flex;
            z-index: 20;
            align-items: center;

            .btnCOn {
                margin-left: 220px;

                button {
                    width: 60px;
                    height: 50px;
                    background: #FFFFFF;
                    box-shadow: 5px 5px 30px rgba(166, 36, 75, 0.1);
                    border-radius: 10px;
                    margin: 10px;
                    border: none;
                    outline: none;

                    svg {
                        width: 15px;
                    }

                    &:active {
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                    }
                }
            }

        }

        .tMainSlideCon {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            position: relative;
            left: 220px;

            .sliderIMGMCON {
                display: flex;
                align-items: flex-end;
                transition: all 0.5s ease;
                position: relative;
                left: auto;
                z-index: 10;

                &.ZE {
                    left: -210px;

                    .imgTh {

                        img {
                            opacity: 0;
                        }
                    }

                    .Ize {
                        img {

                            // transition: all 0s ease !important;
                            height: 587px;
                            width: 410px;
                        }
                    }
                }

                &.SIX {
                    left: 450px;

                    transition: all 0s ease !important;

                    .Ize {
                        transition: all 0s ease !important;

                        img {
                            transition: all 0s ease !important;
                        }
                    }

                    .Fone {
                        opacity: 1;
                    }

                    .Ftwo {
                        opacity: 1;
                    }


                    .imgTh {

                        transition: all 0s ease !important;

                        img {
                            transition: all 0s ease !important;
                            height: 587px;
                            // height: 587px;
                            width: 410px;
                            // margin-right: 0px;
                        }
                    }
                }

                .Fimg {
                    transition: all 0.5s ease;
                    opacity: 0;
                    transition: all 0s ease !important;

                    img {
                        transition: all 0s ease !important;
                    }
                }

                &.BOA {
                    left: 0px;
                    transition: all 0s ease !important;

                    .imgO {
                        img {
                            transition: all 0s ease !important;
                            height: 587px;
                            width: 410px;
                            // margin-right: 0px;
                        }
                    }
                }



                &.OA {
                    left: 0px;
                    // transition: all 0s ease !important;

                    .imgO {
                        img {
                            // transition: all 0s ease !important;
                            height: 587px;
                            width: 410px;
                            // margin-right: 0px;
                        }
                    }
                }

                &.TA {
                    left: 230px;

                    .Fone {
                        opacity: 1;
                    }

                    .imgT {
                        img {
                            height: 587px;
                            width: 410px;
                            // margin-right: 0px;
                        }
                    }
                }

                &.THA {
                    left: 450px;

                    // transition: all 0s ease !important;
                    .Fone {
                        opacity: 1;
                    }

                    .Ftwo {
                        opacity: 1;
                    }

                    .imgTh {

                        // transition: all 0s ease !important;
                        img {
                            // transition: all 0s ease !important;
                            height: 587px;
                            // height: 587px;
                            width: 410px;
                            // margin-right: 0px;
                        }
                    }
                }



                &.IMGR {
                    left: 660px;

                    .Fth {
                        opacity: 1
                    }

                    .Fone {
                        opacity: 1;
                        transition: all 0.5s ease !important;

                        img {
                            transition: all 0.5s ease !important;
                            height: 587px;
                            width: 410px;
                            // margin-right: 0px;
                        }
                    }

                    .Ftwo {
                        transition: all 0.5s ease !important;
                        opacity: 1;

                        img {
                            transition: all 0.5s ease !important;
                        }
                    }


                }
            }

            .sliderItemOwn {



                &.active {
                    img {

                        height: 587px;
                        width: 410px;
                        margin-right: 0px;
                    }
                }

                .sliderImg {
                    transition: all 0.4s ease;

                    margin-right: 30px;
                    height: 250px;
                    width: 190px;
                    border-radius: 30px;
                }
            }
        }
    }
}